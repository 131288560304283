import React from 'react';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ModificaOspite } from 'Containers/functions';
import 'Css/rfid.css';
import CardIcon from 'Img/rounded-rectangle.png';
import { BiRfid } from "react-icons/bi";

class Tessere extends React.Component {

  constructor(props) {
    super(props);
    this.keyComponent = 0
    this.state = {}

    this.props.getRfids();
  }

  componentDidMount(){
    this.props.setSidebarVoice("tessere");
  }

  render() {
    let mobileVer = window.outerWidth < 455;
    let rfidsHtml = this.props.rfids && this.props.rfids.length > 0 ? this.props.rfids.map((rfid) => {
      return (
        <div>
          <img width="20" src={CardIcon} />
          <label style={mobileVer ? { fontSize: "150%" } : {}}>{rfid.name}</label>
        </div>
      )
    }) : null;
    return (
      <>
        {/* <h1 className="section-title tessere"><BiRfid/> TESSERE</h1> */}

        <div className="row text-center" style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
          <div className={mobileVer ? "col" : "col-5"} style={{ margin: "auto" }}>
            <div className="row text-center" style={{ overflow: "hidden", padding: "1em", fontSize: mobileVer ? "3vw" : "initial" }}>
              <div className="col text-left">
                <button className="dmd-btn dmd-btn-blue" onClick={() => this.props.getRfids()} style={{ padding: mobileVer ? "0.5em 1.5em" : ".5em" }}>
                  <i className={"fas fa-sync " + (mobileVer ? "" : "mr-1")}></i>
                  {mobileVer ? "" : "Aggiorna"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {this.props.getRfidsLoading ?
          <>
            <div className="row mt-4 text-center" style={{ overflow: "hidden" }}>
              <div className="col-5" style={{ margin: "auto", textAlign: "center" }}>
                <CircularProgress />
              </div>
            </div>
            <div className="row text-center" style={{ overflow: "hidden" }}>
              <div className="col-5" style={{ margin: "auto", textAlign: "center" }}>
                Lettura delle informazioni da visualizzare in corso...
              </div>
            </div>
          </>
          :
          <>
            <div className="row text-center" style={{ overflow: "hidden" }}>
              <div className={mobileVer ? "col" : "col-5"} style={{ margin: "auto" }}>
                {rfidsHtml ?
                  <div id="rfid-cards">
                    <label id="rfid-cards-head-title">NOME</label>
                    {rfidsHtml}
                  </div>
                  :
                  "Nessun elemento."
                }
              </div>
            </div>
          </>
        }

        <ModificaOspite></ModificaOspite>
      </>
    )
  }
}

export default Tessere
export { Tessere }