import { observable, action, makeObservable } from "mobx";


class RfidStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.service = rootStore.service
    makeObservable(this);
  }

  @observable config = require('../../config/config.json');

  @observable rfids = null;
  @observable getRfidsLoading = false;
  @observable availablesRfids = null;

  @action
  getRfids() {
    this.getRfidsLoading = true;
    let params = {}
    this.service.doAjax('GET', this.config.rfidAPI + '/read?email='+this.rootStore.userStore.getUser().email, params).then(response => {
      this.getRfidsLoading = false;
      if (response.success) {
        this.rfids = response.result;
      } else {

      }
    });
  }

  @action
  getAvailablesRfids(guestId) {
    let params = {}
    this.service.doAjax('GET', this.config.userAPI + '/getRfids?ownerEmail='+this.rootStore.userStore.getUser().email + "&&guestId="+guestId, params).then(response => {
      if (response.success) {
        this.availablesRfids = response.result;
      } else {

      }
    });
  }

}

export default RfidStore;