import React from 'react';

import { Link } from 'react-router-dom';

const withContextPath = (path) => `${process.env.CONTEXT_PATH}/${path}`;

class Dashbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {

    return (
      <>
        <Link className="dashboard-box" to={this.props.to}>
          <h1>{this.props.icon || 0}</h1>
          <label>{this.props.label || ""}</label>
        </Link>
      </>
    )
  }
}

export default Dashbox
export { Dashbox }