import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { DeviceCard, DevicesTools } from 'Containers/main_layout';

class PuntiControllo extends React.Component {

  constructor(props) {
    super(props);
    this.keyComponent = 0
    this.state = {
      searchedDeviceName: ''
    }

    this.props.getAll();

    setInterval(() => {
      this.props.getAll(true)
    }, 1000);
  }

  componentDidMount() {
    this.props.setSidebarVoice("dispositivi");
  }

  onOpenControlPoint(serialNumber, deviceName) {
    this.props.setShowModaleConferma({
      header: "Apertura remota",
      body: <>Aprire in modo remoto il dispositivo <strong>{deviceName}</strong>?</>,
      esegui: () => {
        this.props.openControlPoint(serialNumber);
        this.props.setShowModaleConferma(null);
      }
    });
  }

  onNuovoUtente() {
    this.props.setUserToEdit(null);

    let config = require('../../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/nuovo_ospite";
  }

  setSearchedDeviceName(searchedDeviceName) {
    this.setState({
      searchedDeviceName: searchedDeviceName
    })
  }

  render() {
    let devicesHtml = this.props.devices && this.props.devices.length > 0 ? this.props.devices
      .filter((device) => {
        if (this.state.searchedDeviceName != '' && device.name.toLowerCase().indexOf(this.state.searchedDeviceName.toLowerCase()) == -1) {
          return false;
        }
        return true;
      })
      .map((device) => {
        return (
          <DeviceCard device={device} />
        )
      }) : null;

    return (
      <>
        <DevicesTools setSearchedDeviceName={(deviceName) => this.setSearchedDeviceName(deviceName)} />

        {
          this.props.devicesLoading ?
            <div className="row mt-4 text-center" style={{ overflow: "hidden" }}>
              <div className="col-5" style={{ margin: "auto", textAlign: "center" }}>
                <CircularProgress />
              </div>
            </div>
            :
            <>

              <div className="dmd-table-content">
                {devicesHtml && devicesHtml.length != 0 ?
                  <>
                    {devicesHtml}
                  </>
                  :
                  "Nessun dispositivo trovato."
                }
              </div>
            </>
        }
      </>
    )
  }
}

export default PuntiControllo
export { PuntiControllo }