import React from 'react';

import Dashbox from './dashbox';
import { BiRfid } from "react-icons/bi";
import { Link } from 'react-router-dom';
import 'Css/profile.css';
import { FaUserCircle } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { HiDeviceTablet } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";

const withContextPath = (path) => `${process.env.CONTEXT_PATH}/${path}`;

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      user: {},
      userInitialized: false
    }
  }

  componentDidMount() {
    this.props.setSidebarVoice("pannello");
  }

  updateUserField(name, value){
    let user = this.state.user;
    user[name] = value;
    this.setState({
      user: user
    });
  }

  render() {
    let user = this.props.getUser();

    if(!this.state.userInitialized){
      this.setState({
        userInitialized: true,
        user: user
      });
    }

    return (
      <div id="profile">
        <h1>Il tuo profilo personale</h1>
        <div id="profile-image-box"><div id="profile-image">{user.name[0] + user.lastName[0]}</div></div>

        <div style={{marginTop: '2em'}}>Nome: <input type="text" disabled={!this.state.editMode} onChange={(e)=>this.updateUserField("name", e.currentTarget.value)} value={this.state.user?.name}/></div>
        <div>Cognome: <input type="text" disabled={!this.state.editMode} onChange={(e)=>this.updateUserField("lastName", e.currentTarget.value)} value={this.state.user?.lastName}/></div>
        <div>Email: <input type="text" disabled={!this.state.editMode} onChange={(e)=>this.updateUserField("email", e.currentTarget.value)} value={this.state.user?.email}/></div>
        <div>Cellulare: <input type="text" disabled={!this.state.editMode} onChange={(e)=>this.updateUserField("number", e.currentTarget.value)} value={this.state.user?.number}/></div>

        {/* <div id="buttonGroup">
          {this.state.editMode ?
            <>
              <button>Salva</button>
              <button onClick={() => this.setState({ editMode: false, userInitialized: false })}>Annulla</button>
            </>
            :
            <button onClick={() => this.setState({ editMode: true })}>Modifica</button>
          }
        </div> */}
      </div>
    )
  }
}

export default Profile
export { Profile }