import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModaleConferma extends React.Component {

  constructor(props) {
    super(props);
  }

  onConferma() {
    this.props.showModaleConferma.esegui();
  }
  onAnnulla(){
    this.props.showModaleConferma.cancel();
  }

  render() {
    return this.props.showModaleConferma ? (
      <Modal show={this.props.showModaleConferma ? true : false} onHide={() => this.props.setShowModaleConferma(null)}>
        <Modal.Header closeButton={true} closeLabel={"X"}>
          <Modal.Title>{this.props.showModaleConferma.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.showModaleConferma.body}</Modal.Body>
        <Modal.Footer>
          {this.props.showModaleConferma.hideCancelButton ? "" :
            <Button variant="secondary" onClick={() => {this.props.setShowModaleConferma(null); this.onAnnulla(); }}>
              Annulla
            </Button>
          }
          <Button variant={this.props.showModaleConferma.confirmStyle || "primary"} disabled={this.props.showModaleConferma.loading} onClick={() => this.onConferma()}>
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>
    ) : ""
  }
}

export default ModaleConferma
export { ModaleConferma }