// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#profile-image {
    width: 4em;
    height: 4em;
    padding: 1.5em;
    border: 1px solid rgba(0,0,0,.4);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 200%;
}

#profile>div {
    margin: 1em;
    display: flex;
    justify-content: space-between;
}

#profile>h1 {
    text-align: center;
    font-size: 130%;
    margin-top: .35em;
    font-weight: bold;
}

#profile button {
    padding: .5em;
    border: 0;
    background: rgba(80, 80, 220, .9);
    color: rgb(255, 255, 255);
    border-radius: 8px;
}

#profile input{
    border:0;
    border-bottom: 1px solid rgb(0,0,0);
}

#profile #buttonGroup {
    width: 15em;
    margin: auto;
    margin-top: 2.5em;
}
#profile #buttonGroup button {
    margin: auto;
}

#profile #profile-image-box #profile-image {
    margin: auto;
}

#profile {
    width: 20em;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./css/profile.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,cAAc;IACd,gCAAgC;IAChC,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,iCAAiC;IACjC,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,QAAQ;IACR,mCAAmC;AACvC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["#profile-image {\n    width: 4em;\n    height: 4em;\n    padding: 1.5em;\n    border: 1px solid rgba(0,0,0,.4);\n    border-radius: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-weight: bold;\n    font-size: 200%;\n}\n\n#profile>div {\n    margin: 1em;\n    display: flex;\n    justify-content: space-between;\n}\n\n#profile>h1 {\n    text-align: center;\n    font-size: 130%;\n    margin-top: .35em;\n    font-weight: bold;\n}\n\n#profile button {\n    padding: .5em;\n    border: 0;\n    background: rgba(80, 80, 220, .9);\n    color: rgb(255, 255, 255);\n    border-radius: 8px;\n}\n\n#profile input{\n    border:0;\n    border-bottom: 1px solid rgb(0,0,0);\n}\n\n#profile #buttonGroup {\n    width: 15em;\n    margin: auto;\n    margin-top: 2.5em;\n}\n#profile #buttonGroup button {\n    margin: auto;\n}\n\n#profile #profile-image-box #profile-image {\n    margin: auto;\n}\n\n#profile {\n    width: 20em;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
