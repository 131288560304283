import React from 'react';

import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 'Css/nuovo_dispositivo.css';

import Signal100 from 'Img/signal100-l.png'
import Signal80 from 'Img/signal80-l.png'
import Signal50 from 'Img/signal50-l.png'
import Signal10 from 'Img/signal10-l.png'


class NuovoDispositivo extends React.Component {

  constructor(props) {
    super(props);
    this.keyComponent = 0
    this.state = {
      tempoImpulso: 2
    };

    this.props.getAll();
  }

  onAnnulla() {
    let config = require('../../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/dispositivi";
  }

  onRicerca() {
    this.setState({
      loadingDeviceList: true
    })
    navigator.bluetooth.requestDevice({
      filters: [{
        services: ['4fafc201-1fb5-459e-8fcc-c5c9c331914b']
      }]
    })
      .then(device => {
        // Ottenuto il device mi connetto al GATT Server
        this.setState({
          connectedDevice: device
        });
        return device.gatt.connect();
      })
      .then(server => {
        return server.getPrimaryService('4fafc201-1fb5-459e-8fcc-c5c9c331914b');
      })
      .then(service => {
        setInterval(() => {
          this.onSendPulse();
        }, 2000);

        for (var index = 0; this.props.devices && index < this.props.devices.length; index++) {
          let deviceInProps = this.props.devices[index];
          if (deviceInProps.deviceId == service.device.id) {
            toast.info("Attenzione! Il device risulta essere già registrato.", {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
        }
        this.setState({
          serialNumber: service.device.name,
          deviceId: service.device.id
        });
        return service.getCharacteristic('beb5483e-36e1-4688-b7f5-ea07361b26a8');
      })
      .then(characteristic => {
        this.setState({
          characteristic: characteristic
        })
        return characteristic.readValue();
      })
      .then(value => {
        let characteristicValue = new TextDecoder().decode(value);
        if (characteristicValue.indexOf("networksList") != -1) {
          let networksList = JSON.parse(characteristicValue);
          this.setState({
            networksList: networksList
          });
        }
        this.setState({
          loadingDeviceList: false,
          setupReady: true
        })
      })
      .catch(error => {
        console.log("si e' verificato un errore");
        this.setState({
          loadingDeviceList: false
        })
      });

  }

  onSendPulse = () => {
    if (this.props.isCheckingNetworkAuth() || (!this.props.deviceSetupProcedureStarted && !this.state.deviceId) || this.props.deviceSetupProcedureStarted.setupDone) { return; }

    let pulseText = "Pulse";
    let buffer = new ArrayBuffer(pulseText.length);
    let dataView = new DataView(buffer);
    for (var i = 0; i < pulseText.length; i++) {
      dataView.setUint8(i, pulseText.charAt(i).charCodeAt());
    }
    this.state.characteristic.writeValue(buffer)
      .then(() => {
        this.props.resetPulseErrorCounter();
      })
      .catch(error => {
        if (error.message.indexOf("GATT operation already in progress") == -1 && this.props.getAndIncrementPulseErrorCounter() == 3) {
          this.performDeviceDisconnectionOperations();
        }
      });
  }

  performDeviceDisconnectionOperations = () => {
    console.log("Dispositivo disconnesso");
    console.trace();
    let config = require('../../../../config/config.json');
    const context = `/${config.context}`
    this.showModalError("Il dispositivo si è disconnesso", context + "/dispositivi");
  }

  onRegistra() {
    this.props.register({
      wifiName: this.state.wifiSSID,
      wifiPassword: this.state.wifiPassword,
      name: this.state.newDeviceName,
      serialNumber: this.props.deviceSetupProcedureStarted.serialNumber,
      deviceId: this.props.deviceSetupProcedureStarted.deviceId,
      tempoImpulso: this.state.tempoImpulso,
      pulseMode: "DIRECT"
    });

    setTimeout(() => {
      let informaRegistrazione = "DOMODROIDconfermaSalvataggioDispositivoRegistrato";
      let buffer = new ArrayBuffer(informaRegistrazione.length);
      let dataView = new DataView(buffer);
      for (var i = 0; i < informaRegistrazione.length; i++) {
        dataView.setUint8(i, informaRegistrazione.charAt(i).charCodeAt());
      }
      this.props.deviceSetupProcedureStarted.characteristic.writeValue(buffer);
    }, 3000);
    
  }

  onContinuaStep1() {
    document.getElementById("continue-btn").setAttribute("disabled", "disabled");
    this.startStep(2);
  }

  getDesc(descNumber) {
    return document.getElementsByClassName("desc" + descNumber)[0];
  }

  startStep(stepNumber) {
    console.log("startStep - " + stepNumber);
    switch (stepNumber) {
      case 1:
        this.step1();
        break;
      case 2:
        this.step2();
        break;
      case 3:
        this.step3();
        break;
    }
  }

  step1() {
    $(() => {
      let deviceName = document.getElementById("device-name");
      let continueBtn = document.getElementById("continue-btn");

      setTimeout(() => {
        this.getDesc(1).classList.add("op1");
        setTimeout(() => {
          this.getDesc(2).classList.add("op1");
          setTimeout(() => {
            this.getDesc(3).classList.add("op1");
            setTimeout(() => {
              deviceName.classList.add("op1");
              setTimeout(() => {
                continueBtn.classList.add("op1");
                setTimeout(() => {
                  document.getElementById("device-name").focus();
                }, 1000);
              }, 1000);
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    });
  }

  step2() {
    if (this.state.newDeviceName) {
      let deviceName = document.getElementById("device-name");
      let continueBtn = document.getElementById("continue-btn");
      let deviceNameContainer = document.getElementById("device-name-container");
      let networkListToSelect = document.getElementById("network-list-to-select");

      deviceName.setAttribute("disabled", "disabled");
      this.getDesc(1).classList.remove("op1");
      this.getDesc(2).classList.remove("op1");
      this.getDesc(3).classList.remove("op1");
      deviceName.classList.remove("op1");
      continueBtn.classList.remove("op1");

      setTimeout(() => {
        deviceNameContainer.remove();
        setTimeout(() => {
          this.getDesc(1).classList.add("op1");
          setTimeout(() => {
            this.getDesc(2).classList.add("op1");
            setTimeout(() => {
              this.getDesc(3).classList.add("op1");
              setTimeout(() => {
                console.log("state", this.state);
                let networks = this.props.deviceSetupProcedureStarted.networksList.networksList;

                networks.forEach(network => {
                  let networkSignal = network.substring(network.length - 6, network.length - 1);
                  networkSignal = networkSignal.replace("(", "").replace(")", "");
                  networkSignal = 100 + parseInt(networkSignal); //Il segnale e' letto in maniera negativa, ottengo il corrispettivo positivo

                  let networkSignalIcon = "";
                  if (networkSignal <= 10) {
                    networkSignalIcon = Signal10;
                  } else if (networkSignal <= 30) {
                    networkSignalIcon = Signal50;
                  } else if (networkSignal <= 60) {
                    networkSignalIcon = Signal80;
                  } else {
                    networkSignalIcon = Signal100;
                  }

                  let networkNameFormatted = network.substring(0, network.length - 6).trim();
                  networkListToSelect.innerHTML += `<a class='network-element' data=${networkNameFormatted}><label>` + networkNameFormatted + `</label><img src=${networkSignalIcon} class='network-element-signal'/></a>`;
                });

                let showNetworkElementTimeout = 500;
                [].forEach.call(document.getElementsByClassName("network-element"), element => {
                  setTimeout(() => {
                    element.classList.add("op1");
                    element.addEventListener("click", (e) => this.askNetworkPassword(element.innerText));
                  }, showNetworkElementTimeout);
                  showNetworkElementTimeout += 500;
                });

              }, 1000);
            }, 1000);
          }, 1000);
        }, 1000);
      }, 2000);
    }
  }

  step3() {
    this.onRegistra();

    let networkList = document.getElementById("network-list");
    let cancelBtn = document.getElementById("cancel-btn");
    let devicePaired = document.getElementById("device-paired");

    networkList.classList.add("op0");
    cancelBtn.classList.add("op0");
    setTimeout(() => {
      networkList.remove();
      devicePaired.classList.add("dShow");
      setTimeout(() => {
        this.getDesc(1).classList.add("op1");
        setTimeout(() => {
          this.getDesc(2).classList.add("op1");
          setTimeout(() => {
            this.getDesc(3).classList.add("op1");
            setTimeout(() => {
              let devicePairedOkBtn = document.getElementById("device-paired-ok-btn")
              devicePairedOkBtn.classList.add("op1");
              devicePairedOkBtn.addEventListener("click", () => {
                devicePairedOkBtn.setAttribute("disabled", "disabled");
                this.getDesc(1).classList.remove("op1");
                this.getDesc(2).classList.remove("op1");
                this.getDesc(3).classList.remove("op1");
                devicePairedOkBtn.classList.remove("op1");
                devicePairedOkBtn.classList.add("op0");
                setTimeout(() => this.onAnnulla(), 1250);
              });
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    }, 1250);
  }

  askNetworkPassword(networkName) {
    console.log("Requesting password for network");
    console.log(networkName);
    this.showModal(networkName);
    this.setState({
      wifiSSID: networkName,
      wifiPassword: ""
    })
  }

  showModal(title) {
    let modalInput = document.getElementById("modal-input");

    document.getElementById("modal-bg").classList.add("dShow");
    document.getElementById("modal").classList.add("dShow");

    document.getElementById("modal-title").innerText = title;
    document.getElementById("modal-input").removeAttribute("disabled");
    document.getElementById("modal-input").focus();

    modalInput.value = "";
  }
  hideModal() {
    let modalConfirmBtn = document.getElementById("modal-confirm-btn");

    document.getElementById("modal-bg").classList.remove("dShow");
    document.getElementById("modal").classList.remove("dShow");
    document.getElementById("modal").classList.remove("op-transition-on");
    document.getElementById("modal").classList.remove("op0");

    document.getElementById("modal-confirm-loading").classList.remove("dFlex");
    modalConfirmBtn.classList.remove("dNone");
    document.getElementById("modal-confirm-loading").classList.remove("op1");
  }
  checkNetworkPassword() {
    let modalConfirmBtn = document.getElementById("modal-confirm-btn");

    document.getElementById("modal-confirm-loading").classList.add("dFlex");
    modalConfirmBtn.classList.add("dNone");
    setTimeout(() => {
      document.getElementById("modal-confirm-loading").classList.add("op1");
    }, 250);
    document.getElementById("modal-input").setAttribute("disabled", "disabled");

    this.connectDeviceToWifi();
  }

  abortConnectionToWifi(wifiOkInterval) {
    this.connectionResult(false);
    clearInterval(wifiOkInterval);
    this.setState({
      verificaConnessione: false,
    });
    this.props.setCheckingNetworkAuth(false);
  }

  connectDeviceToWifi() {
    let ssidAndPwd = this.state.wifiSSID + "!%#%!" + this.state.wifiPassword + "!%#%!" + this.props.deviceSetupProcedureStarted.deviceId + "!%#%!";
    let buffer = new ArrayBuffer(ssidAndPwd.length);
    let dataView = new DataView(buffer);
    for (var i = 0; i < ssidAndPwd.length; i++) {
      dataView.setUint8(i, ssidAndPwd.charAt(i).charCodeAt());
    }
    this.props.deviceSetupProcedureStarted.characteristic.writeValue(buffer);

    let wifiOkInterval;
    let isWifiOkCounter = 3;

    this.setState({
      verificaConnessione: true
    });

    wifiOkInterval = setInterval(() => {
      this.props.setCheckingNetworkAuth(true);

      if (isWifiOkCounter == 0) {
        this.abortConnectionToWifi(wifiOkInterval);
      }

      this.props.deviceSetupProcedureStarted.characteristic.readValue()
        .then((value) => {
          this.props.resetOperationErrorWhileCommunicatingCounter();
          if (new TextDecoder().decode(value) == "wifiok") {
            this.setState({
              connessioneVerificata: true,
              verificaConnessione: false
            });
            this.connectionResult(true);
            toast.info("Connessione WiFi verificata. E' possibile completare la procedura registrando il dispositivo.", {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            clearInterval(wifiOkInterval);
          }
          if (new TextDecoder().decode(value) == "wifinotconnected") {
            console.log("Connessione non riuscita");
            this.abortConnectionToWifi(wifiOkInterval);
          }
          isWifiOkCounter--;
        })
        .catch(error => {
          if (error.message.indexOf("GATT operation already in progress") == -1 && this.props.getAndIncrementOperationErrorWhileCommunicatingCounter() == 3) {
            console.log(error);
            this.performDeviceDisconnectionOperations();
          }
        });
    }, 2000);

  }

  connectionResult(isSuccess) {
    if (isSuccess) {
      document.getElementById("modal").classList.add("op-transition-on");
      document.getElementById("modal").classList.add("op0");
      this.startStep(3);
      setTimeout(() => {
        this.hideModal();
      }, 1250);
    } else {
      this.hideModal();
      this.showModalError("Connessione non riuscita");
    }
  }

  showModalError(message, redirect) {
    let modalError = document.getElementById("modal-error");

    this.setState({
      modalErrorShowed: true,
    });

    this.props.setModalErrorMessage({ value: message, redirect: redirect });

    document.getElementById("modal-bg").classList.add("dShow");
    modalError.classList.add("dShow");
    setTimeout(() => {
      modalError.classList.add("op1");
    }, 250);
  }

  hideModalError() {
    let modalError = document.getElementById("modal-error");

    if (this.props.modalErrorMessage.redirect) {
      setTimeout(() => {
        location.href = this.props.modalErrorMessage.redirect;
      }, 1000);
    } else {
      document.getElementById("modal-bg").classList.remove("dShow");
    }

    this.setState({
      modalErrorShowed: false,
    });

    modalError.classList.remove("dShow");
    modalError.classList.remove("op1");
  }

  render() {
    let mobileVer = window.outerWidth < 455;

    if (this.state.setupReady && !this.props.deviceSetupProcedureStarted) {
      this.props.setDeviceSetupProcedureStarted(this.state);
      this.startStep(1);
    }

    return (
      <>
        {this.props.deviceSetupProcedureStarted ?
          <div id="device-setup-procedure">
            <div id="device-name-container" className="container">
              <h1 className="desc1">Inserisci un nome</h1>
              <h1 className="desc2">per il tuo nuovo</h1>
              <h1 className="desc3">dispositivo</h1>
              <input type="text" id="device-name" autoComplete='device-name-ac' onChange={(e) => this.setState({ newDeviceName: e.currentTarget.value })} onKeyDown={(e) => { if (e.key.toLowerCase() == 'enter') { this.onContinuaStep1() } }} />
              <button id="continue-btn" className="confirm-style" onClick={() => this.onContinuaStep1()}>Continua &#62;</button>
            </div>

            <div id="network-list" className="container">
              <h1 className="desc1">Seleziona</h1>
              <h1 className="desc2">una rete</h1>
              <h1 className="desc3">a cui connetterti</h1>
              <div id="network-list-to-select"></div>
            </div>

            <div id="device-paired" className="container">
              <h1 className="desc1">Dispositivo</h1>
              <h1 className="desc2">associato</h1>
              <h1 className="desc3 confirm-style">con successo</h1>
              <button id="device-paired-ok-btn">Ok</button>
            </div>

            <div id="modal-bg" onClick={() => !this.state.modalErrorShowed && !this.props.isCheckingNetworkAuth() && !this.props.modalErrorMessage && !this.props.modalErrorMessage.redirect ? this.hideModal() : ""}></div>
            <div id="modal">
              <h1 id="modal-title">Nome rete</h1>
              <div className="separator"></div>
              <h2>Password richiesta</h2>
              <input type="text" id="modal-input" autoFocus="true" autoComplete='password-input-ac' onChange={(e) => this.setState({ wifiPassword: e.currentTarget.value })} onKeyDown={(e) => { if (e.key.toLowerCase() == 'enter') { this.checkNetworkPassword() } }} />
              <button id="modal-confirm-btn" className="confirm-style" onClick={() => this.checkNetworkPassword()}>Conferma {'>'}</button>
              <div id="modal-confirm-loading">
                <div></div>
              </div>
            </div>
            <div id="modal-error">
              <h2>{this.props.modalErrorMessage ? this.props.modalErrorMessage.value : "Errore"}</h2>
              <button id="modal-error-ok-btn" onClick={() => this.hideModalError()}>Ok</button>
            </div>

            <button id="cancel-btn" onClick={() => this.onAnnulla()}>X Annulla</button>
          </div>
          :
          <>
            <div className="row mt-4 text-center" style={{ overflow: "hidden" }}>
              <div className={mobileVer ? "col" : "col-5"} style={{ margin: "auto", textAlign: "center" }}>
                <Alert severity="info">In questa sezione è possibile associare ed aggiungere un nuovo dispositivo. <strong>Per iniziare</strong> premi il pulsante <strong>Ricerca</strong> e seleziona il dispositivo.</Alert>
              </div>
            </div>

            <div className="row mt-4 text-center" style={{ overflow: "hidden" }}>
              <div className={mobileVer ? "col" : "col-5"} style={{ margin: "auto", textAlign: "center" }}>
                <button className="btn btn-danger mr-1" onClick={() => this.onAnnulla()}>Annulla</button>
                {this.state.loadingDeviceList ?
                  <button className="btn btn-primary" disabled>Ricerca</button>
                  :
                  <button className="btn btn-primary" onClick={() => this.onRicerca()}>Ricerca</button>
                }
              </div>
            </div>
          </>
        }
      </>
    )
  }
}

export default NuovoDispositivo
export { NuovoDispositivo }