import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { Button, Typography, Container, Alert } from '@mui/material';
import Modal from "react-bootstrap/Modal";

class Messaggi extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.props.getTemplates();
  }

  componentDidMount(){
    this.props.setSidebarVoice("messaggi");
  }

  changeTemplateTo(number, event) {
    if (event.target.nodeName == "I") { return; }
    this.props.saveSmsTemplateSelected(number);
    this.setState({
      templateDefault: number
    });
  }

  getClassName(number) {
    let checkedClass = "fas fa-check-circle text-success";
    let uncheckedClass = "far fa-circle";
    return this.state.templateDefault == number ? checkedClass : uncheckedClass;
  }

  getStyle(number) {
    return this.state.templateDefault == number ? { border: "2px #28a745 solid", cursor: "default" } : { cursor: "pointer" };
  }

  getToastHeaderStyle(number) {
    return { position: "relative", color: (this.state.templateDefault == number ? "#28a745" : "inherit") };
  }

  editTemplate(number) {
    this.setState({
      showModalEdit: number
    })
    if(number){
      this.setState({
        modalEditText: this.state.savedUser.smsTemplates[number - 1].text
      })
    }
  }

  saveTemplate() {
    this.props.saveSmsTemplate({
      number: this.state.showModalEdit,
      text: this.state.modalEditText
    });
    this.editTemplate(false)
  }

  changeModalEditText(event) {
    this.setState({
      modalEditText: event.currentTarget.value
    });
  }

  updateSmsTemplates(savedUserParam) {
    let savedUser = this.state.savedUser || savedUserParam;
    if (savedUser) {
      savedUser.smsTemplates.forEach(smsTemplate => {
        let trovato = false;
        this.props.user.smsTemplates.forEach(smsTemplateProps => {
          if (smsTemplate.text == smsTemplateProps.text) {
            trovato = true;
          }
        });
        if (!trovato) {
          this.setState({
            getTemplates: this.props.user,
          });
          //break
        }
      });
    }
  }

  render() {
    let mobileVer = window.outerWidth < 455;
    let colClassName = mobileVer ? "col-10 mb-5" : "col";
    let editBtnStyle = { position: "absolute", right: 0, top: "-30px", fontSize: "200%", color: "orange" };

    if (this.props.user && !this.state.savedUser) {
      this.setState({
        savedUser: this.props.user
      });
      this.updateSmsTemplates(this.props.user);
    }

    if (this.props.user && this.state.savedUser && this.props.user.lastUpdate != this.state.savedUser.lastUpdate) {
      this.updateSmsTemplates();
    }

    if (!this.state.templateDefault) {
      this.setState({
        templateDefault: this.props.getUser().smsTemplateSelected + ""
      })
    }

    return (
      <>
       <div className="row mt-3 mb-1" style={{ overflow: "hidden" }}>
          <div className={mobileVer ? "col" : "col-5"} style={{ margin: "auto" }}>
            <Alert severity="info">
              <div>Per cambiare il template di default cliccare su di esso.</div>
              <div>Il template selezionato verrà utilizzato per l'invio delle informazioni all'utente.</div>
              <div>Per modificare il testo di un template è sufficiente cliccare sull'icona <i className="fas fa-edit"></i>.</div>
              </Alert>
          </div>
        </div>

        <div className="row mt-5">
          <div className={colClassName} style={{ margin: "auto" }}>
            <Toast onClick={(e) => this.changeTemplateTo("1", e)} style={this.getStyle("1")}>
              <Toast.Header closeButton={false} style={this.getToastHeaderStyle("1")}>
                <i className="fas fa-edit" style={editBtnStyle} onClick={() => this.editTemplate("1")}></i>
                <i className="fas fa-comment-alt mr-2"></i>
                <strong className="me-auto">Template SMS #1</strong>
              </Toast.Header>
              <Toast.Body>
                {this.state.savedUser ? this.state.savedUser.smsTemplates[0].text : ""}
                <hr />
                <div className="text-center">
                  <i className={this.getClassName("1")}></i>
                </div>
              </Toast.Body>
            </Toast>
          </div>
          <div className={colClassName} style={{ margin: "auto" }}>
            <Toast onClick={(e) => this.changeTemplateTo("2", e)} style={this.getStyle("2")}>
              <Toast.Header closeButton={false} style={this.getToastHeaderStyle("2")}>
                <i className="fas fa-edit" style={editBtnStyle} onClick={() => this.editTemplate("2")} ></i>
                <i className="fas fa-comment-alt mr-2"></i>
                <strong className="me-auto">Template SMS #2</strong>
              </Toast.Header>
              <Toast.Body>
                {this.state.savedUser ? this.state.savedUser.smsTemplates[1].text : ""}
                <hr />
                <div className="text-center">
                  <i className={this.getClassName("2")}></i>
                </div>
              </Toast.Body>
            </Toast>
          </div>
          <div className={colClassName} style={{ margin: "auto" }}>
            <Toast onClick={(e) => this.changeTemplateTo("3", e)} style={this.getStyle("3")}>
              <Toast.Header closeButton={false} style={this.getToastHeaderStyle("3")}>
                <i className="fas fa-edit" style={editBtnStyle} onClick={() => this.editTemplate("3")} ></i>
                <i className="fas fa-comment-alt mr-2"></i>
                <strong className="me-auto">Template SMS #3</strong>
              </Toast.Header>
              <Toast.Body>
                {this.state.savedUser ? this.state.savedUser.smsTemplates[2].text : ""}
                <hr />
                <div className="text-center">
                  <i className={this.getClassName("3")}></i>
                </div>
              </Toast.Body>
            </Toast>
          </div>
        </div>

        <Modal
          show={this.state.showModalEdit}
          onHide={() => this.editTemplate(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Modifica Template #{this.state.showModalEdit}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-2" style={{ overflow: "hidden" }}>
              <div className="col" style={{ margin: "auto" }}>
                <Alert severity="info">
                  <strong>Legenda parole dinamiche:</strong>
                  <br/>
                  <ul>
                    <li>Ospite: %utente%</li>
                    <li>Punto di accesso: %punto%</li>
                    <li>Pin: %pin%</li>
                    <li>Data scadenza: %scadenza%</li>
                  </ul>
                  </Alert>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <textarea style={{ width: "100%", height: "7em", resize: "none", padding: "3px" }} onChange={(e) => this.changeModalEditText(e)}>
                  {
                    this.state.modalEditText
                  }
                </textarea>
              </div>
            </div>
            <div className="row">
              <div className="col text-right">
                {this.props.saveSmsTemplateLoading ?
                  <button className="btn btn-sm btn-success mr-1 disabled" disabled style={{ cursor: "wait" }}>Salva</button>
                  :
                  <button className="btn btn-sm btn-success mr-1" onClick={() => this.saveTemplate()}>Salva</button>
                }

                <button className="btn btn-sm btn-secondary" onClick={() => this.editTemplate(false)}>Chiudi</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default Messaggi
export { Messaggi }