import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FaUser } from "react-icons/fa6";

import 'Css/guests.css';

import { ModificaOspite } from 'Containers/functions';

class Guests extends React.Component {

  constructor(props) {
    super(props);
    this.keyComponent = 0
    this.state = {}

    this.props.readUsers();
  }

  componentDidMount() {
    this.props.setSidebarVoice("ospiti");
  }

  onNuovoOspite() {
    this.props.setUserToEdit(null);

    let config = require('../../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/nuovo_ospite";
  }

  onGestisci(user) {
    this.props.getAll();
    this.props.editGuest(user.id);
  }

  render() {
    let devicesHtml = this.props.users && this.props.users.length > 0 ? this.props.users.map((user) => {
      return (
        <div className="guests-guest-row" onClick={() => this.onGestisci(user)}>
          <FaUser />
          <label>{user.name} {user.lastName}</label>
        </div>
      )
    }) : null;
    return (
      <>
        {/* <h1 className="section-title"><FaUserCircle /> OSPITI</h1> */}
        <div id="guests">

          <button className="dmd-btn dmd-btn-orange" onClick={() => this.onNuovoOspite()}>
            <i className="fas fa-plus mr-1" ></i>Nuovo</button>

          <div className="separator"></div>

          {this.props.readUsersLoading ?
            <>
              <div className="row mt-4 text-center" style={{ overflow: "hidden" }}>
                <div className="col-5" style={{ margin: "auto", textAlign: "center" }}>
                  <CircularProgress />
                </div>
              </div>
              <div className="row text-center" style={{ overflow: "hidden" }}>
                <div className="col-5" style={{ margin: "auto", textAlign: "center" }}>
                  Lettura delle informazioni da visualizzare in corso...
                </div>
              </div>
            </>
            :
            <>
              {devicesHtml ?
                <div id="guests-list">
                  {devicesHtml}
                </div>
                :
                "Nessun elemento."
              }
            </>
          }
        </div>

        <ModificaOspite></ModificaOspite>
      </>
    )
  }
}

export default Guests
export { Guests }