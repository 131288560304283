import { observable, action, makeObservable } from "mobx";

class LogStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.service = rootStore.service
    makeObservable(this);
  }

  @observable config = require('../../config/config.json');

  @observable logs = null;
  @observable countLogs = null;
  @observable fetchLogLoading = false;

  @action
  fetchLog(from) {
    this.fetchLogLoading = true;
    let params = {}
    this.service.doAjax('GET', this.config.controlPointAPI + '/report?email=' + this.rootStore.userStore.getUser().email + '&from=' + from, params).then(response => {
      if (response.success) {
        this.logs = {
          pins: response.result.logPins,
          rfids: []
        };
        this.countLogs = response.result.count;
      } else {
        //
      }
      this.fetchLogLoading = false;
    });
  }

}

export default LogStore;