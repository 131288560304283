import "core-js/stable";

import React from 'react';

import { Provider } from 'mobx-react';
import { syncHistoryWithStore } from 'mobx-react-router';
import browserHistory from './common/history';

import 'Css/app.css';
import 'Css/dmd-btns.css';

import { onRequestConfig, onRequestConfigError, onResponse, onResponseError, onNoServerResponse } from 'Services/network-config'
import RootStore from './stores'
import { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

const rootStore = new RootStore(onRequestConfig, onRequestConfigError, onResponse, onResponseError, onNoServerResponse);
const history = syncHistoryWithStore(browserHistory, rootStore.routingStore);

import { MainLayout } from 'Containers/main_layout'

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.unsubscribeFromStore = history.listen((location, action) => {
      // Handle location changes if needed
    });
  }

  componentWillUnmount() {
    if (this.unsubscribeFromStore) {
      this.unsubscribeFromStore();
    }
  }

  render() {
    return (
      <MainLayout />
    );
  }
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <HistoryRouter history={history}>
    <Provider rootStore={rootStore}>
      <App />
    </Provider>
  </HistoryRouter>
);
