import React from 'react';

import 'Css/nuovo_ospite.css';
import { ThumbUpSharp } from '@mui/icons-material';

class NuovoOspite extends React.Component {

  constructor(props) {
    super(props);
    this.keyComponent = 0
    this.state = {
      sendSMS:false
    };

    this.props.setGuestRegistrationProcedureStarted(true);
    this.props.getAll();
  }

  onAnnulla() {
    let config = require('../../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/guests";
  }

  onContinuaStep1() {
    if (this.state.nome && this.state.cognome) {
      this.setState({
        nomeError: false,
        cognomeError: false
      });

      this.startStep(2);
    } else {
      if (!this.state.nome) {
        this.setState({
          nomeError: true
        });
      } else {
        this.setState({
          nomeError: false
        });
      }
      if (!this.state.cognome) {
        this.setState({
          cognomeError: true
        });
      } else {
        this.setState({
          cognomeError: false
        });
      }
    }
  }
  onContinuaStep2() {
    this.startStep(3);
  }

  getDesc(descNumber) {
    return document.getElementsByClassName("desc" + descNumber)[0];
  }

  startStep(stepNumber, extra) {
    // console.log("startStep - " + stepNumber);
    switch (stepNumber) {
      case 1:
        this.step1();
        break;
      case 2:
        this.step2();
        break;
      case 3:
        this.step3();
        break;
      case 4:
        this.step4(extra);
        break;
    }
  }

  step1() {
    let primoStep = document.getElementById("primo-step");
    let secondoStep = document.getElementById("secondo-step");

    primoStep.classList.remove("d-none");
    primoStep.classList.add("step-on");
    primoStep.classList.remove("step-off");
    secondoStep.classList.remove("step-on");
    secondoStep.classList.add("step-off");

    let sipario = document.getElementById("sipario-w");
    sipario.classList.remove("sipario-w-off");
    secondoStep.classList.remove("container-border");
  }

  step2() {
    this.props.generatePin(1);

    let primoStep = document.getElementById("primo-step");
    let secondoStep = document.getElementById("secondo-step");

    primoStep.classList.remove("step-on");
    primoStep.classList.add("step-off");
    primoStep.classList.add("d-none");
    secondoStep.classList.remove("step-off");
    secondoStep.classList.add("step-on");
  }

  step3() {
    let userToRegister = {
      name: this.state.nome,
      lastName: this.state.cognome,
      email: this.state.email,
      cellNumber: this.state.cellulare,
      pin: [this.props.generatedPin1+""],
      sendSMS: false
    }

    this.props.registerUser(userToRegister);

    let secondoStep = document.getElementById("secondo-step");
    let terzoStep = document.getElementById("terzo-step");
    let quartoStep = document.getElementById("quarto-step");

    secondoStep.classList.remove("step-on");
    secondoStep.classList.add("step-off");
    secondoStep.classList.add("d-none");
    terzoStep.classList.remove("step-off");
    terzoStep.classList.add("step-on");
    terzoStep.classList.remove("d-none");

    quartoStep.classList.remove("step-on");
    quartoStep.classList.add("step-off");
    quartoStep.classList.add("d-none");
  }

  step4(device) {
    this.setState({
      deviceName: device.name,
      deviceId: device.id
    });
    

    let terzoStep = document.getElementById("terzo-step");
    let quartoStep = document.getElementById("quarto-step");

    terzoStep.classList.remove("step-on");
    terzoStep.classList.add("step-off");
    terzoStep.classList.add("d-none");
    quartoStep.classList.remove("step-off");
    quartoStep.classList.add("step-on");
    quartoStep.classList.remove("d-none");
  }

  step5() {
    this.props.pairUserToDevice({
      userId: this.props.lastUserRegisteredId,
      deviceId: this.state.deviceId,
      deviceName: this.state.deviceName,
      untilDate: this.state.tempoSelected != "illimitato" ? this.state.tempoSelectedLimitato : "unlimited",
      sendSMS: this.state.sendSMS
    });

    let quartoStep = document.getElementById("quarto-step");
    let quintoStep = document.getElementById("quinto-step");

    quartoStep.classList.remove("step-on");
    quartoStep.classList.add("step-off");
    quartoStep.classList.add("d-none");
    quintoStep.classList.remove("step-off");
    quintoStep.classList.add("step-on");
  }

  onSalvaAssociazione() {
    if (this.state.tempoSelected == "illimitato" ||
      (this.state.tempoSelected == "limitato" && this.state.tempoSelectedLimitato)) {
      this.setState({ associaTempoError: false });
      //todo salvataggio BE
      this.step5();
    } else {
      this.setState({ associaTempoError: true });
    }
  }

  formattaData(data) {
    if (!data) { return ""; }
    return data.split("-")[2] + "/" + data.split("-")[1] + "/" + data.split("-")[0];
  }

  render() {
    let mobileVer = window.outerWidth < 455;

    let devicesHtml = this.props.devices && this.props.devices.length > 0 ? this.props.devices.map((device) => {
      return <button className="devices" disabled={this.state.nonAssociare} onClick={() => this.startStep(4, device)}>{device.name}</button>
    }) : "";

    $(() => {

      if (!this.state.siparioOff) {
        this.setState({
          siparioOff: true
        });

        let sipario = document.getElementById("sipario-h");

        sipario.classList.add("sipario-h-off");

        setTimeout(() => {
          let container = document.getElementsByClassName("container")[0];
          container.classList.add("container-border");
        }, 1000);
      }

    });

    return (
      <>
        <div id="guest-registration-procedure">
          <div id="primo-step" className="step-on container">
            <div id="sipario-h"></div>
            <h1><i className="fa-solid fa-address-card"></i>Nuovo ospite</h1>
            <input type="text" autoFocus={true} className={this.state.nomeError ? "input-error" : ""} placeholder="Nome" onChange={(e) => { this.setState({ nome: e.currentTarget.value }) }} value={this.state.nome} />
            <input type="text" className={this.state.cognomeError ? "input-error" : ""} placeholder="Cognome" onChange={(e) => { this.setState({ cognome: e.currentTarget.value }) }} value={this.state.cognome} />
            <input type="text" placeholder="Email" onChange={(e) => { this.setState({ email: e.currentTarget.value }) }} value={this.state.email} />
            <input type="text" placeholder="Cellulare" onChange={(e) => { this.setState({ cellulare: e.currentTarget.value }) }} value={this.state.cellulare} />
            <div id="btns">
              <button id="cancel-btn" onClick={() => this.onAnnulla()}>X Annulla</button>
              <button id="continue-btn" className="confirm-style" onClick={() => this.onContinuaStep1()}>Avanti &#62;</button>
            </div>
          </div>
          <div id="secondo-step" className="step-off container container-border">
            <h1>Ho generato un PIN per <label>{this.state.nome} {this.state.cognome}</label></h1>
            <h2><i className="fa-solid fa-circle-info fa-xs"></i> Il PIN è il codice di accesso per l'ospite</h2>
            <input type="text" value={this.props.generatedPin1} onClick={(e) => e.preventDefault()} disabled={true} />
            <button id="regenerate-btn" onClick={() => this.props.generatePin(1)}><i className="fa-solid fa-arrows-rotate"></i> Rigenera</button>
            <div id="btns">
              <button id="cancel-btn" onClick={() => this.step1()}>&#60; Indietro</button>
              <button id="continue-btn" className="confirm-style" onClick={() => this.onContinuaStep2()}>Avanti &#62;</button>
            </div>
          </div>
          <div id="terzo-step" className="step-off container container-border">
            {this.props.registerUserLoading ?
              <h3 style={{color:"black!important"}}>Sto salvando l'ospite...</h3>
              :
              <>
                <h1>Ospite <label>{this.state.nome} {this.state.cognome}</label> salvato <i className="fa-solid fa-check"></i></h1>
                <h2>Vuoi associarlo ad un dispositivo?</h2>
                <button onClick={() => { this.onAnnulla(); this.setState({ nonAssociare: true }) }} id="btn-no-bind">Non associare</button>
                {devicesHtml}
              </>}
          </div>
          <div id="quarto-step" className="step-off container container-border">
            <h1>Associo l'ospite <label>{this.state.nome} {this.state.cognome}</label></h1>
            <h1>al dispositivo</h1>
            <h1><label>{this.state.deviceName}</label></h1>
            <h2 className={this.state.associaTempoError ? "color-error" : ""}>Per quanto tempo?</h2>
            <button className={this.state.tempoSelected == "illimitato" ? "selected" : ""} onClick={() => this.setState({ tempoSelected: "illimitato" })}>Illimitato</button>
            <button className={this.state.tempoSelected == "limitato" ? "selected" : ""} onClick={() => this.setState({ tempoSelected: "limitato" })}>Fino al...</button>
            {this.state.tempoSelected == "limitato" ?
              <input type="date" min={new Date().toISOString().split('T')[0]} onChange={(e) => this.setState({ tempoSelectedLimitato: e.currentTarget.value })} />
              : ""}

            {this.state.cellulare ?
            <button id="btn-sms" className={this.state.sendSMS ? "btn-sms-on" : ""} onClick={() => this.setState({sendSMS: !this.state.sendSMS})}>Invia SMS di notifica all'ospite {this.state.sendSMS ? <i className="fa-solid fa-check"></i> : ""}</button>
            : ""}
            <div id="btns">
              <button id="cancel-btn" onClick={() => this.step3()}>&#60; Indietro</button>
              <button id="continue-btn" className="confirm-style" onClick={() => this.onSalvaAssociazione()}>Salva <i className="fa-solid fa-floppy-disk"></i></button>
            </div>
          </div>
          <div id="quinto-step" className="step-off container container-border">
            <h1>L'ospite <label>{this.state.nome} {this.state.cognome}</label></h1>
            <h1>è stato associato al dispositivo</h1>
            <h1><label>{this.state.deviceName}</label></h1>
            {this.state.tempoSelected == "illimitato" ?
              <h1>per un tempo illimitato</h1>
              :
              <>
                <h1>fino al</h1>
                <h1><label>{this.formattaData(this.state.tempoSelectedLimitato)}</label></h1>
              </>
            }
            <div id="btns">
              <button id="cancel-btn" onClick={() => this.onAnnulla()}>Chiudi</button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default NuovoOspite
export { NuovoOspite }