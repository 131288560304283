import { inject } from 'mobx-react'

import { Header as HeaderComponent } from '../common/components/main_layout/header'
import { Footer as FooterComponent } from '../common/components/main_layout/footer'
import { ControlPanel as ControlPanelComponent } from '../common/components/control_panel'
import { Profile as ProfileComponent } from '../common/components/profile'
import { Login as LoginComponent } from '../common/components/login'
import { Register as RegisterComponent } from '../common/components/register'
import { MainLayout as MainLayoutComponent } from '../common/components/main_layout/main_layout'
import { SideBar as SideBarComponent } from '../common/components/main_layout/side_bar'
import { ModaleConferma as ModaleConfermaComponent } from '../common/components/main_layout/modale_conferma'
import { ModaleSMS as ModaleSMSComponent } from '../common/components/main_layout/modale_sms'
import { Report as ReportComponent } from '../common/components/report'
import { Messaggi as MessaggiComponent } from '../common/components/messaggi'
import { PasswordRecovery as PasswordRecoveryComponent } from '../common/components/password_recovery'
import { PasswordRecoveryForm as PasswordRecoveryFormComponent } from '../common/components/password_recovery_form'

import { DeviceCard as DeviceCardComponent } from '../common/components/punti_controllo/device_card';
import { DevicesTools as DevicesToolsComponent } from '../common/components/punti_controllo/devices_tools';

const Profile = inject(({ rootStore }) => ({
  setSidebarVoice: (val) => rootStore.globalsStore.setSidebarVoice(val),
  getUser: () => rootStore.userStore.getUser()

}))(ProfileComponent)

const DevicesTools = inject(({ rootStore }) => ({
  setUserToEdit: (value) => rootStore.userStore.setUserToEdit(value),

}))(DevicesToolsComponent)

const DeviceCard = inject(({ rootStore }) => ({
  setShowModaleConferma: (value) => rootStore.infoStore.setShowModaleConferma(value),
  openControlPoint: (serialNumber) => rootStore.deviceStore.openControlPoint(serialNumber),
  openControlPointLoading: rootStore.deviceStore.openControlPointLoading

}))(DeviceCardComponent)

const PasswordRecoveryForm = inject(({ rootStore }) => ({
  getUser: () => rootStore.userStore.getUser(),
  sendRecoveryLink: (externalParams) => rootStore.userStore.sendRecoveryLink(externalParams),
  changePassword: (externalParams) => rootStore.userStore.changePassword(externalParams),
  changePasswordLoading: rootStore.userStore.changePasswordLoading

}))(PasswordRecoveryFormComponent)

const PasswordRecovery = inject(({ rootStore }) => ({
  getUser: () => rootStore.userStore.getUser(),
  sendRecoveryLink: (externalParams) => rootStore.userStore.sendRecoveryLink(externalParams),
  sendRecoveryLinkLoading: rootStore.userStore.sendRecoveryLinkLoading,
  sendRecoveryLinkSuccess: rootStore.userStore.sendRecoveryLinkSuccess,

}))(PasswordRecoveryComponent)

const Messaggi = inject(({ rootStore }) => ({
  getUser: () => rootStore.userStore.getUser(),
  saveSmsTemplate: (templateInfo) => rootStore.userStore.saveSmsTemplate(templateInfo),
  saveSmsTemplateLoading: rootStore.userStore.saveSmsTemplateLoading,
  getTemplates: () => rootStore.userStore.getTemplates(),
  saveSmsTemplateSelected: (templateNumber) => rootStore.userStore.saveSmsTemplateSelected(templateNumber),
  user: rootStore.userStore.user,
  setSidebarVoice: (val) => rootStore.globalsStore.setSidebarVoice(val)

}))(MessaggiComponent)

const Report = inject(({ rootStore }) => ({
  logs: rootStore.logStore.logs,
  fetchLog: (from) => rootStore.logStore.fetchLog(from),
  countLogs: rootStore.logStore.countLogs,
  fetchLogLoading: rootStore.logStore.fetchLogLoading,
  setSidebarVoice: (val) => rootStore.globalsStore.setSidebarVoice(val),
  generateReportPdf: (rowsToGenerator) => rootStore.deviceStore.generateReportPdf(rowsToGenerator),
  setShowModaleConferma: (value) => rootStore.infoStore.setShowModaleConferma(value),
  deleteRecordRows: (rowsToDelete) => rootStore.deviceStore.deleteRecordRows(rowsToDelete)

}))(ReportComponent)

const ModaleConferma = inject(({ rootStore }) => ({
  showModaleConferma: rootStore.infoStore.showModaleConferma,
  setShowModaleConferma: (value) => rootStore.infoStore.setShowModaleConferma(value)

}))(ModaleConfermaComponent)

const ModaleSMS = inject(({ rootStore }) => ({
  showModaleSMS: rootStore.infoStore.showModaleSMS,
  setShowModaleSMS: (value) => rootStore.infoStore.setShowModaleSMS(value)

}))(ModaleSMSComponent)

const SideBar = inject(({ rootStore }) => ({
  showSideBar: rootStore.infoStore.showSideBar,
  getUser: () => rootStore.userStore.getUser(),
  login: (user) => rootStore.userStore.login(user),
  setSelectedVoiceMenu: (voiceMenu) => rootStore.infoStore.setSelectedVoiceMenu(voiceMenu),
  setLastSelectedVoiceMenu: () => rootStore.infoStore.setLastSelectedVoiceMenu(),
  selectedVoiceMenu: rootStore.infoStore.selectedVoiceMenu,
  setSideBarVisibility: (value) => rootStore.infoStore.setSideBarVisibility(value),
  sidebarVoice: rootStore.globalsStore.sidebarVoice

}))(SideBarComponent)

const MainLayout = inject(({ rootStore }) => ({
  getUser: () => rootStore.userStore.getUser(),
  setSideBarVisibility: (value) => rootStore.infoStore.setSideBarVisibility(value),
  logout: () => rootStore.userStore.logout(),
  getStompClient: () => rootStore.globalsStore.getStompClient(),
  deviceSetupProcedureStarted: rootStore.deviceStore.deviceSetupProcedureStarted,
  guestRegistrationProcedureStarted: rootStore.userStore.guestRegistrationProcedureStarted
}))(MainLayoutComponent)

const Header = inject(({ rootStore }) => ({
  getUser: () => rootStore.userStore.getUser(),
  showSideBar: rootStore.infoStore.showSideBar,
  setSideBarVisibility: (value) => rootStore.infoStore.setSideBarVisibility(value),
}))(HeaderComponent)

const Footer = inject(({ rootStore }) => ({}))(FooterComponent)

const ControlPanel = inject(({ rootStore }) => ({
  getUser: () => rootStore.userStore.getUser(),
  setUserToEdit: (value) => rootStore.userStore.setUserToEdit(value),
  logout: () => rootStore.userStore.logout(),
  setSidebarVoice: (val) => rootStore.globalsStore.setSidebarVoice(val)

}))(ControlPanelComponent)

const Login = inject(({ rootStore }) => ({
  loginLoading: rootStore.userStore.loginLoading,
  setLoginLoading: (value) => rootStore.userStore.setLoginLoading(value),
  login: (user) => rootStore.userStore.login(user),
  getUser: () => rootStore.userStore.getUser()

}))(LoginComponent)

const Register = inject(({ rootStore }) => ({
  register: (user) => rootStore.userStore.register(user),
  registerLoading: rootStore.userStore.registerLoading
}))(RegisterComponent)

export {
  ControlPanel,
  Header,
  Footer,
  MainLayout,
  Login,
  Register,
  SideBar,
  ModaleConferma,
  Report,
  Messaggi,
  ModaleSMS,
  PasswordRecovery,
  PasswordRecoveryForm,
  DeviceCard,
  DevicesTools,
  Profile
}
