import { observable, action, makeObservable } from "mobx";

class InfoStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.service = rootStore.service
    makeObservable(this);
  }

  @observable config = require('../../config/config.json');

  @observable selectedVoiceMenu = "dashboard";

  @action
  setSelectedVoiceMenu(value) {
    this.selectedVoiceMenu = value;
  }

  @observable visibleCompanies = [];

  @action
  setVisibleCompanies(value) {
    setTimeout(() => { //Timeout per evitare che chi chiama e legge il valore settato continui a chiamarlo subito inutilmente
      this.visibleCompanies = value;
    }, 250);
  }

  @observable showSideBar = true;

  @action
  setSideBarVisibility(val) {
    if(!$(".menu-item-text-col").hasClass("menu-item-text-col-deactivated")){
      $(".menu-item-text-col").addClass("menu-item-text-col-deactivated");
      $("#sidebar").addClass("sidebar-stretched");
      $("#logoNoLogo").css("opacity", "0");
      setTimeout(() => {
        $(".menu-item-text-col").hide();
        $("#logoNoLogo").hide();
      }, 100);
    }else{
      $("#sidebar").removeClass("sidebar-stretched");
      $("#logoNoLogo").css("opacity", "1");
      setTimeout(() => {
        $("#logoNoLogo").show();
        $(".menu-item-text-col").show();
        $(".menu-item-text-col-deactivated").removeClass("menu-item-text-col-deactivated");
      }, 400);
    }
  }

  @observable showModaleConferma = null;

  @action
  setShowModaleConferma(value) {
    this.showModaleConferma = value;
  }

  @observable showModaleSMS = null;

  @action
  setShowModaleSMS(value) {
    this.showModaleSMS = value;
  }

}

export default InfoStore;