import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  basename: process.env.CONTEXT_PATH // Imposta il basename per il browser history
});

history.listen(({location, action}) => {
  console.log(
    `The current URL is ${location.pathname}${location.search}${location.hash}`
  );
  console.log(`The last navigation action was ${action}`);
});
export default history;