import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from 'reactstrap';
import { FaFileExport } from "react-icons/fa";
import { PiNumpadBold } from "react-icons/pi";
import { IoIosRefreshCircle } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";
import 'Css/report.css';

class Report extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toolTipShow: false,
      pageNumber: 0
    };

    this.props.fetchLog(0);
    this.targetRef = React.createRef();

  }

  componentDidMount() {
    this.props.setSidebarVoice("report");

    $(() => {
      document.querySelector("body").addEventListener("scroll", () => this.onScroll());
    });
  }

  onChangePage(event, newPage) {
    if (!newPage) { return; }
    this.props.fetchLog(newPage - 1);
    this.setState({
      pageNumber: parseInt(newPage - 1)
    })
  }

  onScroll() {
    this.setState({ toolTipShow: false });
  }

  onManageToolTip(toolTipId) {
    let toolTipShow = this.state.toolTipShow;
    if (!toolTipShow) {
      toolTipShow = {};
    }
    toolTipShow[toolTipId] = toolTipShow[toolTipId] != null ? null : true;
    this.setState({ toolTipShow: toolTipShow })
  }

  downloadPDF = () => {
    let rowsToGenerator = [];
    for(let k in this.state.checkReportRows){
      if(this.state.checkReportRows[k]){
        rowsToGenerator.push(k);
      }
    }
    if(rowsToGenerator.length == 0){
      alert("Selezionare le righe da esportare");
      return;
    }

    this.props.generateReportPdf(rowsToGenerator);
  }

  deleteReport = () => {
    let rowsToDelete = [];
    for(let k in this.state.checkReportRows){
      if(this.state.checkReportRows[k]){
        rowsToDelete.push(k);
      }
    }
    if(rowsToDelete.length == 0){
      alert("Selezionare le righe da eliminare");
      return;
    }
    
    this.props.setShowModaleConferma({
      header: "Elimina record",
      body: "Procedere con l'eliminazione dei record selezionati?",
      esegui: () => {
        this.props.deleteRecordRows(rowsToDelete);
        this.props.setShowModaleConferma(null)
      }
    });

    
  }

  initReportCheckbox(index, value) {
    let checkReportRows = this.state.checkReportRows ? this.state.checkReportRows : {};

    if (!(index in checkReportRows)) {
      checkReportRows[index] = value;

      this.setState({
        checkReportRows: checkReportRows
      });
    }
  }

  toggleReportCheckbox(index, value) {
    let checkReportRows = this.state.checkReportRows;
    if (!checkReportRows) {
      checkReportRows = {};
    }

    if (index == "all") {
      let checkReportRowsAll = this.state.checkReportRowsAll ? false : true;
      this.setState({
        checkReportRowsAll: checkReportRowsAll
      });

      let keys = Object.keys(checkReportRows);
      keys.forEach(k => checkReportRows[k] = checkReportRowsAll);

    } else {
      checkReportRows[index] = checkReportRows[index] ? false : true;
    }

    this.setState({
      checkReportRows: checkReportRows
    });
  }

  render() {
    let mobileVer = window.outerWidth < 455;
    let logsHtml = this.props.logs && this.props.logs.pins && this.props.logs.pins.length > 0 ? this.props.logs.pins.map((log, index) => {
      let date = new Date(log.date);
      date.setHours(date.getHours() - 2)
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let hours = date.getHours();
      if (hours < 10) {
        hours = "0" + hours;
      }
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let seconds = date.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      let toolTipId = ([day, month, date.getFullYear()].join("/") + [hours, minutes, seconds].join(":")).replaceAll("/", "").replaceAll(":", "");
      let logFromApp = log.pin && log.pin.indexOf("@") != -1;

      !this.state.checkReportRows || !(log.id in this.state.checkReportRows) ? this.initReportCheckbox(log.id, false) : "";
      return (
        <TableRow style={index % 2 ? { background: "rgba(0,0,35,0.1)" } : { background: "white" }}>
          <TableCell align="center"><button className="checkReportRow" onClick={() => this.toggleReportCheckbox(log.id)}>{this.state.checkReportRows && this.state.checkReportRows[log.id] ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}</button></TableCell>
          {mobileVer && logFromApp ?
            <TableCell align="center">
              <button className="btn btn-sm btn-secondary" href="#" id={"tt_" + toolTipId}><i className="fas fa-user-shield"></i></button>
              <Tooltip placement="right" isOpen={this.state.toolTipShow[toolTipId]} target={"tt_" + toolTipId} toggle={() => this.onManageToolTip(toolTipId)}>
                {log.pin}
              </Tooltip>
            </TableCell>
            :
            <TableCell style={{ maxWidth: "10em", wordWrap: "break-word" }} align="center">
              <div>{log.name} {log.lastName}</div>
              <div style={{ fontWeight: logFromApp ? '' : 'bold', color: 'rgba(0,0,0,.75)' }}>{log.name ? <PiNumpadBold /> : ""} {log.pin ? log.pin : ""}</div>
            </TableCell>
          }
          <TableCell align="center">{log.deviceName || '-'}</TableCell>
          <TableCell align="center">{[day, month, date.getFullYear()].join("/")}</TableCell>
          <TableCell align="center">{[hours, minutes, seconds].join(":")}</TableCell>
        </TableRow>
      )
    }) : null;

    return (
      <div id="report" ref={this.targetRef}>
        <div id="reportHeadTools">
          <button id="exportBtn" onClick={() => this.downloadPDF()}>
            <FaFileExport />
            <label>Export PDF</label>
          </button>
          <button id="refreshBtn" onClick={() => { this.setState({ pageNumber: 0 }); this.props.fetchLog(0); }}>
            <IoIosRefreshCircle />
            <label>Aggiorna</label>
          </button>
          <button id="deleteBtn" onClick={() => this.deleteReport()}>
            <FaTrash />
            <label>Elimina</label>
          </button>
        </div>

        <div id="line" />

        <div id="reportBody">
          <label id="reportInfoText">Sono mostrati gli ultimi 30 accessi.</label>

          {this.props.fetchLogLoading ?
            <CircularProgress />
            :
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableCell align="center"><button className="checkReportRow" onClick={() => this.toggleReportCheckbox("all")}>{this.state.checkReportRowsAll ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}</button></TableCell>
                  <TableCell align="center">
                    <strong>Utente</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Dispositivo</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Data</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Orario</strong>
                  </TableCell>
                </TableHead>
                <TableBody>
                  {logsHtml}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
      </div>
    )
  }
}

export default Report
export { Report }