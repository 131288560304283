// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#guests {
    margin: 1em;
    display: flex;
    flex-direction: column;
}
#guests-list{
    margin-top: 1em;
}

.guests-guest-row{
    width: auto;
    border: 0;
    box-shadow: 0 0 2px 3px rgb(0, 132, 213);
    background: rgb(0, 132, 213);
    margin-top: 1.2em;
    padding: 1em;
    color: white;
    border-radius: 4px;
    cursor:pointer;
    display: flex;
    justify-content: left;
    align-items: center;
}
.guests-guest-row:active{
    background: rgb(0, 102, 183);
}
.guests-guest-row svg{
    margin-right: .5em;
}

#guests .separator{
    border-bottom: 1px dashed rgba(50,50,100,.4);
    margin-top: 1em;
    margin-bottom: 1em;
}`, "",{"version":3,"sources":["webpack://./css/guests.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,SAAS;IACT,wCAAwC;IACxC,4BAA4B;IAC5B,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,qBAAqB;IACrB,mBAAmB;AACvB;AACA;IACI,4BAA4B;AAChC;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,4CAA4C;IAC5C,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["#guests {\n    margin: 1em;\n    display: flex;\n    flex-direction: column;\n}\n#guests-list{\n    margin-top: 1em;\n}\n\n.guests-guest-row{\n    width: auto;\n    border: 0;\n    box-shadow: 0 0 2px 3px rgb(0, 132, 213);\n    background: rgb(0, 132, 213);\n    margin-top: 1.2em;\n    padding: 1em;\n    color: white;\n    border-radius: 4px;\n    cursor:pointer;\n    display: flex;\n    justify-content: left;\n    align-items: center;\n}\n.guests-guest-row:active{\n    background: rgb(0, 102, 183);\n}\n.guests-guest-row svg{\n    margin-right: .5em;\n}\n\n#guests .separator{\n    border-bottom: 1px dashed rgba(50,50,100,.4);\n    margin-top: 1em;\n    margin-bottom: 1em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
