import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import Logo from 'Img/logo.png'

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onLogin() {
    if (!this.state.email || !this.state.password) {
      this.setState({
        emailError: !this.state.email ? true : false,
        passwordError: !this.state.password ? true : false
      });
      return;
    }

    let user = {
      email: this.state.email,
      password: this.state.password
    }

    this.props.login(user);
  }

  onRegistrati() {
    this.setState({
      goToRegistratiLoading: true
    })
    let config = require('../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/register"
  }

  onRecoveryPassword(){
    let config = require('../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/password_recovery"
  }

  render() {
    //Se loggato vai in home
    if (this.props.getUser() && this.state.config) {
      const context = `/${this.state.config.context}`;
      location.href = context + "/dispositivi"
    }
    return (
      <div className="row mt-4">
        <div className="col">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div >
              <div className="row">
                <div className="col text-center">
                  <img src={Logo} width="250px" style={{ marginLeft: "0", width: "250px" }} />
                </div>
              </div>
              <hr />

              <div style={{ textAlign: "center" }}>
                <Avatar style={{ margin: "auto" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Accesso
                </Typography>
              </div>
              <form noValidate>
                <TextField
                  error={this.state.emailError}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Indirizzo Email"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={(e) => { this.setState({ emailError: false, email: e.currentTarget.value }) }}
                  autoFocus
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.onLogin()
                    }
                  }}
                />
                <TextField
                  error={this.state.passwordError}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={(e) => { this.setState({ passwordError: false, password: e.currentTarget.value }) }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.onLogin()
                    }
                  }}
                />
                {this.props.loginLoading ?
                  <div style={{ textAlign: "center", marginTop: "1em" }}>
                    <CircularProgress />
                  </div>
                  :
                  <>
                  <a href="#" onClick={() => this.onRecoveryPassword()}>
                    Password dimenticata?
                    </a>
                    <Button
                      type="button"
                      onClick={() => this.onLogin()}
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "1em" }}
                    >
                      Entra
                    </Button>
                    <Grid container style={{ marginTop: "1em", textAlign: "center" }}>
                      <Grid item style={{ margin: "auto" }}>
                        oppure
                      </Grid>
                    </Grid>
                    {this.state.goToRegistratiLoading ?
                      <div style={{ textAlign: "center", marginTop: "1em" }}>
                        <CircularProgress />
                      </div>
                      :
                      <Button
                        type="button"
                        onClick={() => this.onRegistrati()}
                        fullWidth
                        variant="contained"
                        color="success"
                        style={{ marginTop: "1em" }}
                      >
                        Registrati
                      </Button>
                    }
                  </>
                }
              </form>
            </div>
            <Box mt={5} style={{ textAlign: "center" }}>
              Copyright &#169; DomoDroid 2021
            </Box>
          </Container>
        </div>
      </div>
    )
  }
}

export default Login
export { Login }