import React from 'react';

class DevicesTools extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  onNuovoUtente() {
    this.props.setUserToEdit(null);

    let config = require('../../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/nuovo_ospite";
  }

  render() {
    let devicesToolsStyle = {
      width: '100%',
      height: 'fit-content',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1em',
      margin: 'auto'
    };

    let inputStyle = {
      border: 0,
      borderBottom: '1px solid black',
      background: 'rgba(0,0,0,0)',
      color: 'rgb(0,0,0)',
      width: '100%',
      margin: '1em',
      fontSize: '18px',
      paddingRight: '.5em',
      paddingLeft: '.5em',
      textAlign: 'center',
      marginTop: '2em'
    }

    return (
      <div style={devicesToolsStyle}>
        <div>
          <div></div>
          {/* <button className="dmd-btn dmd-btn-orange" onClick={() => this.onNuovoUtente()}>
            <i className="fas fa-plus mr-1" ></i>
            Nuovo Ospite</button> */}
        </div>
        <input type="text" style={inputStyle} placeholder='Cerca tra i dispositivi' onChange={(e) => this.props.setSearchedDeviceName (e.currentTarget.value)} />
      </div>
    )
  }
}

export default DevicesTools
export { DevicesTools }