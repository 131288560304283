import { observable, action, makeObservable } from "mobx";
import CryptoAES from 'crypto-js/aes';
import { toast } from 'react-toastify';
import React from 'react';
import { DotDotDotLoader } from 'react-dotdotdotloader'

class UserStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.service = rootStore.service
    makeObservable(this);
  }

  @observable user = null;
  @observable config = require('../../config/config.json');

  @observable registerLoading = false;

  @observable guestRegistrationProcedureStarted = false;

  @action
  setGuestRegistrationProcedureStarted(val){
    this.guestRegistrationProcedureStarted = val;
  }

  @observable saveSmsTemplateLoading = false;

  @action
  saveSmsTemplate(templateInfo) {
    this.saveSmsTemplateLoading = true;
    let params = {
      email: this.getUser().email,
      templateNumber: templateInfo.number,
      templateText: templateInfo.text
    }
    this.service.doAjax('POST', this.config.ownerAPI + '/save_sms_template', params).then(response => {
      if (response.success) {
        //this.getTemplates();
        location.reload();
      } else {
        this.saveSmsTemplateLoading = false;
      }
    });
  }

  @action
  saveSmsTemplateSelected(templateNumber) {
    let params = {
      email: this.getUser().email,
      templateNumber: templateNumber
    }
    this.service.doAjax('POST', this.config.ownerAPI + '/save_sms_template_selected', params).then(response => {
      if (response.success) {
        let user = this.user;
        user.smsTemplateSelected = templateNumber;
        this.setUser(user);
        toast.success("Hai modificato con successo il template utilizzato con il numero #" + templateNumber + ".", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  setUser(user) {
    user.lastUpdate = Date.now();
    this.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  }

  @action
  getTemplates() {
    let params = {}

    this.service.doAjax('GET', this.config.ownerAPI + '/get_templates?email=' + this.getUser().email, params).then(response => {
      if (response.success) {
        let user = this.getUser();
        user.smsTemplates = response.result.templates;
        user.smsTemplateSelected = response.result.templateSelected;
        this.setUser(user);
      }
    });
  }


  @observable sendRecoveryLinkLoading = false;
  @observable sendRecoveryLinkSuccess = false;

  @action
  sendRecoveryLink(externalParams) {
    this.sendRecoveryLinkLoading = true;
    let params = {
      email: externalParams.email
    }

    this.service.doAjax('POST', this.config.ownerAPI + '/send_recovery_link', params).then(response => {
      this.sendRecoveryLinkSuccess = true;
      this.sendRecoveryLinkLoading = false;
      if (response.success) {
        toast.info("Link di recupero inviato via mail.", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  @observable changePasswordLoading = false;

  @action
  changePassword(externalParams) {
    this.changePasswordLoading = true;
    let params = {
      code: externalParams.code,
      password: externalParams.password
    }

    this.service.doAjax('POST', this.config.ownerAPI + '/change_password', params).then(response => {
      if (response.success) {
        let messageToShowCrypted = CryptoAES.encrypt("Password modificata con successo.", this.config.phrase);

        const context = `/${this.config.context}`
        location.href = context + "?dmd=" + messageToShowCrypted;
      }else{
        this.changePasswordLoading = false;
      }
    });
  }

  @action
  onDeleteGuest(id) {

    let params = {
      owner: this.getUser().email,
      guestId: id + ""
    }
    this.service.doAjax('POST', this.config.userAPI + '/deleteGuest', params).then(response => {
      if (response.success) {
        this.editGuest(null);
        this.readUsers(true);

        if (response.result){
          toast.success("Ospite eliminato", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } else {
        toast.error("Si e' verificato un problema durante la rimozione dell'ospite. Riprovare.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  @action
  register(owner) {
    this.registerLoading = true;

    let params = {
      password: owner.password,
      email: owner.email,
      name: owner.firstName,
      lastName: owner.lastName,
    }
    this.service.doAjax('POST', this.config.ownerAPI + '/register', params).then(response => {
      if (response.success) {

        if (response.result && response.result.owner) {
          let messageToShowCrypted = CryptoAES.encrypt("Registrazione effettuata. Per completare il processo di registrazione, convalidare l'account tramite il link di verifica inviato all'indirizzo mail specificato.", this.config.phrase);

          const context = `/${this.config.context}`
          location.href = context + "/auth?dmd=" + messageToShowCrypted;
          this.registerLoading = false;
        } else if (response.result && response.result.error) {
          toast.error(response.result.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.registerLoading = false;
          return;
        } else {
          toast.error("Registrazione non effettuata. Errore di comunicazione, riprovare tra qualche minuto.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.registerLoading = false;
          return;
        }

      } else {
        let message = response && response.result ? response.result : "Credenziali errate o servizio non raggiungibile.";
        this.rootStore.modalsStore.setToastError({ header: "Registrazione non effettuata", body: message });
        this.timeoutError = message;
        this.registerLoading = false;
      }
    })
  }

  @observable loginLoading = false;

  @action
  setLoginLoading(value) {
    this.loginLoading = value;
  }

  @action
  login(user) {
    this.loginLoading = true;

    let params = {
      password: user.password,
      email: user.email
    }
    this.service.doAjax('POST', this.config.ownerAPI + '/login', params).then(response => {
      if (response.success) {
        this.user = response.result.user;

        if (this.user) {
          /*           this.user.authorization = response.authorization; */
          this.setUser(this.user);
          const context = `/${this.config.context}`
          location.href = context + "/dispositivi";
        } else if (response.result.error) {
          toast.error(response.result.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          this.loginLoading = false;
          return;
        } else {
          toast.error("Le credenziali inserite sono errate.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          this.loginLoading = false;
          return;
        }

      } else {
        toast.error("Le credenziali inserite sono errate.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        this.loginLoading = false;
      }
    })
  }

  @action
  getUser() {
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    if (user && !Array.isArray(user)) {
      return new Array(user)[0];
    }
    return user ? user[0] : null;
  }

  @action
  logout() {
    if (localStorage.getItem('user')) {
      localStorage.removeItem("user");
      location.reload();
    }
  }

  @observable registerUserLoading = false;
  @observable lastUserRegisteredId = null;

  @action
  registerUser(user) {
    this.registerUserLoading = true;
    let params = {
      ownerEmail: this.getUser().email,
      idStr: user.id ? user.id+"" : null,
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      cellNumber: user.cellNumber,
      pin: user.pin,
      sendSMS: user.sendSMS,
      deviceAssociati: this.rootStore.deviceStore.deviceAssociati,
      rfid: user.rfid,
      isUpdate: user.isUpdate,
      devices: user.devices
    }
    this.service.doAjax('POST', this.config.userAPI + '/register', params).then(response => {
      if (response.success) {

        if (response.result) {
          this.lastUserRegisteredId = response.result.id;
          let registratoOrModificato = params.idStr ? "salvato" : "registrato";

          toast.success("Ospite " + registratoOrModificato, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          let messageToShowCrypted = CryptoAES.encrypt("Ospite " + user.firstName + " " + user.lastName + " " + registratoOrModificato + " correttamente.", this.config.phrase);

          const context = `/${this.config.context}`

          if (params.sendSMS) {
            this.rootStore.infoStore.setShowModaleSMS({
              head:
                <div className="row" style={{ color: "darkorange" }}>
                  <div className="col">
                    <strong>Conferma SMS</strong>
                  </div>
                </div>,
              body:
                <>
                  <div className="row">
                    <div className="col">
                      <strong><i className="fas fa-check text-success mr-1"></i>L'utente è stato salvato.</strong>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      Attendere conferma invio SMS
                      <strong>
                        <DotDotDotLoader amount={3}
                          show={true}
                          interval={500}
                          repeat={true} />
                      </strong>
                    </div>
                  </div>

                </>
            });
          } else {
            //location.href = context + "/guests?dmd=" + messageToShowCrypted;
          }

        } else {
          toast.info("Si e' verificato un problema durante la registrazione.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } else {
        let message = response && response.result ? response.result : "Credenziali errate o servizio non raggiungibile.";
        this.rootStore.modalsStore.setToastError({ header: "Registrazione non effettuata", body: message });
        this.timeoutError = message;
      }

      this.registerUserLoading = false;
    })
  }

  @action
  sendSMS(info) {
    info.email = this.getUser().email;

    this.service.doAjax('POST', this.config.userAPI + '/sendSMS', info).then(response => {
      if (response.success) {

          toast.success("SMS Inviato", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      } else {
        toast.info("Si e' verificato un problema durante l'invio dell'SMS.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      this.readUsersLoading = false;
    })
  }

  @action
  pairUserToDevice(info) {
    let params = {
      ownerEmail: this.getUser().email,
      userId: info.userId,
      deviceId: info.deviceId,
      deviceName: info.deviceName,
      untilDate: info.untilDate,
      sendSMS: info.sendSMS
    }
    this.service.doAjax('POST', this.config.userAPI + '/pairdtou', params).then(response => {
      if (response.success) {

          //OK

      } else {
        toast.info("Si e' verificato un problema durante l'associazione dell'ospite al dispositivo.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      this.readUsersLoading = false;
    })
  }

  @observable guestToEdit = null;

  @action
  editGuest(id){
    if(id != null){
      // console.log("editGuest - " + id);
      this.guestToEdit = this.users.filter(user=> user.id == id)[0];
    }else{
      this.guestToEdit = null;
    }
  }

  @observable users = null;
  @observable readUsersLoading = false;

  @action
  readUsers(hideLoading) {
    this.readUsersLoading = hideLoading ? false : true;
    let params = {
      ownerEmail: this.getUser().email
    }
    this.service.doAjax('POST', this.config.userAPI + '/read', params).then(response => {
      if (response.success) {

        if (response.result) {
          this.users = response.result;
        } else {
          toast.info("Si e' verificato un problema durante il recupero degli utenti associati, provare a ricaricare.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } else {
        toast.info("Si e' verificato un problema durante il recupero degli utenti associati, provare a ricaricare.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      this.readUsersLoading = false;
    })
  }

  @observable userToEdit = null;

  @action
  setUserToEdit(value) {
    localStorage.setItem("userToEdit", JSON.stringify(value));

    const context = `/${this.config.context}`
    location.href = context + "/nuovo_utente";
  }

  @action
  getUserToEdit() {
    this.userToEdit = localStorage.getItem("userToEdit") ? JSON.parse(localStorage.getItem("userToEdit")) : null;
    return this.userToEdit;
  }

  @observable eliminaUtenteLoading = false;

  @action
  eliminaUtente(id) {
    this.eliminaUtenteLoading = true;
    let params = {
      ownerEmail: this.getUser().email,
      idStr: id + ""
    }
    this.service.doAjax('POST', this.config.userAPI + '/delete', params).then(response => {
      if (response.success) {
        let messageToShowCrypted = CryptoAES.encrypt("Ospite eliminato correttamente.", this.config.phrase);

        const context = `/${this.config.context}`
        location.href = context + "/guests?dmd=" + messageToShowCrypted;
      } else {
        toast.info("Si e' verificato un problema durante l'eliminazione.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      this.eliminaUtenteLoading = false;
    })
  }

  @observable userRfid;

  @action
  fetchUserRfid() {
    let params = {}

    this.service.doAjax('GET', this.config.userAPI + '/user_rfid?owner=' + this.getUser().email, params).then(response => {
      if (response.success) {
        this.userRfid = response.result;
      } 
    })
  }

}

export default UserStore;