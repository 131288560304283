import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';


import Logo from 'Img/logo.png'

class PasswordRecoveryForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  sendRecoveryLink() {
    if (!this.state.password || !this.state.confermaPassword) {
      this.setState({
        passwordError: !this.state.password ? true : false
      });
      return;
    }

    let user = {
      code: location.search.split("=")[1],
      password: this.state.password,
    }

    this.props.changePassword(user);
  }

  render() {
    //Se loggato vai in home
    if (this.props.getUser() && this.state.config) {
      const context = `/${this.state.config.context}`;
      location.href = context + "/dispositivi"
    }
    return (
      <div className="row mt-4">
        <div className="col">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div >
              <div className="row">
                <div className="col text-center">
                  <img src={Logo} width="250px" style={{ marginLeft: "0", width: "250px" }} />
                </div>
              </div>
              <hr />

              <div style={{ textAlign: "center" }}>
                <Avatar style={{ margin: "auto" }}>
                  <PasswordIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Recupero password
                </Typography>
              </div>
              <form noValidate>
                <TextField
                  error={this.state.passwordError}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  disabled = {this.props.changePasswordLoading}
                  type="password"
                  id="password"
                  label="Password"
                  name="password"
                  value={this.state.password}
                  onChange={(e) => { this.setState({ passwordError: false, password: e.currentTarget.value }) }}
                  autoFocus
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.sendRecoveryLink()
                    }
                  }}
                />
                <TextField
                  error={this.state.passwordError}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  disabled = {this.props.changePasswordLoading}
                  type="password"
                  id="conferma_password"
                  label="Conferma password"
                  name="conferma_password"
                  value={this.state.confermaPassword}
                  onChange={(e) => { this.setState({ passwordError: false, confermaPassword: e.currentTarget.value }) }}
                  autoFocus
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.sendRecoveryLink()
                    }
                  }}
                />
                <Button
                  type="button"
                  onClick={() => this.sendRecoveryLink()}
                  fullWidth
                  disabled = {this.props.changePasswordLoading}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "1em" }}
                >
                  Cambia password
                </Button>
              </form>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default PasswordRecoveryForm
export { PasswordRecoveryForm }