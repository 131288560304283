import { observable, action, makeObservable } from "mobx";
import React from 'react';

class GlobalsStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.service = rootStore.service
    makeObservable(this);
  }

  @observable stompClient = null;
  @observable config = require('../../config/config.json');

  @observable sidebarVoice = null;

  @action
  setSidebarVoice(sidebarVoice){
    this.sidebarVoice = sidebarVoice;
  }

  getStompClient() {
    // console.log("getStompClient");
    if (this.stompClient == null && this.rootStore.userStore.getUser() != null) {
      // console.log("Registering stomp client...");
      let socket = new SockJS(this.config.messagesAPI + '/notification');
      let userName = this.rootStore.userStore.getUser().email;
      this.stompClient = Stomp.over(socket);
      this.stompClient.debug = null

      // console.log("Subscribing to /topic/notification/...")
      this.stompClient.connect({}, (frame) => {
        this.stompClient.subscribe("/topic/notification/" + userName.trim(), (response) => {
          let data = JSON.parse(response.body);
          // console.log("data", data);

          this.rootStore.infoStore.setShowModaleSMS({
            head:
              <div className="row" style={{ color: "green" }}>
                <div className="col">
                  <strong>SMS confermato</strong>
                </div>
              </div>,
            body:
              <>
                <div className="row">
                  <div className="col">
                    <strong><i className="fas fa-check text-success mr-1"></i>L'utente è stato salvato.</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong><i className="fas fa-check text-success mr-1"></i>{data.message}</strong>
                  </div>
                </div>
              </>,
            confirmed: true
          });

        });
      });
    }
    return this.stompClient;
  }


}

export default GlobalsStore;