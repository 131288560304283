import { inject } from 'mobx-react'

import { PuntiControllo as PuntiControlloComponent } from '../common/components/punti_controllo/punti_controllo'
import { PuntoControllo as PuntoControlloComponent } from '../common/components/punti_controllo/punto_controllo'

import { NuovoDispositivo as NuovoDispositivoComponent } from '../common/components/punti_controllo/nuovo_dispositivo'
import { NuovoOspite as NuovoOspiteComponent } from '../common/components/chiavi/nuovo_ospite'
import { ModificaOspite as ModificaOspiteComponent } from '../common/components/chiavi/modifica_ospite'

import { Guests as GuestsComponent } from '../common/components/chiavi/guests'
import { Tessere as TessereComponent } from '../common/components/chiavi/tessere'

const Guests = inject(({ rootStore }) => ({
  devicesLoading: rootStore.deviceStore.devicesLoading,
  devices: rootStore.deviceStore.devices,
  getAll: () => rootStore.deviceStore.getAll(),

  getDevicePins: (device) => rootStore.deviceStore.getDevicePins(device),
  devicePins: rootStore.deviceStore.devicePins,

  readUsers: () => rootStore.userStore.readUsers(),
  users: rootStore.userStore.users,
  readUsersLoading: rootStore.userStore.readUsersLoading,

  setUserToEdit: (value) => rootStore.userStore.setUserToEdit(value),
  userToEdit: rootStore.userStore.userToEdit,

  editGuest: (id) => rootStore.userStore.editGuest(id),
  setSidebarVoice: (val) => rootStore.globalsStore.setSidebarVoice(val)

}))(GuestsComponent)

const Tessere = inject(({ rootStore }) => ({
  rfids: rootStore.rfidStore.rfids,
  getRfids: () => rootStore.rfidStore.getRfids(),
  getRfidsLoading: rootStore.rfidStore.getRfidsLoading,
  setSidebarVoice: (val) => rootStore.globalsStore.setSidebarVoice(val)

}))(TessereComponent)

const PuntoControllo = inject(({ rootStore }) => ({
  devicesLoading: rootStore.deviceStore.devicesLoading,
  devices: rootStore.deviceStore.devices,
  getAll: (hideLoading) => rootStore.deviceStore.getAll(hideLoading),


  openControlPoint: (serialNumber) => rootStore.deviceStore.openControlPoint(serialNumber),
  openControlPointLoading: rootStore.deviceStore.openControlPointLoading,
  readDetails: (id) => rootStore.deviceStore.readDetails(id),
  register: (device) => rootStore.deviceStore.register(device),
  rimuoviDevice: (id) => rootStore.deviceStore.rimuoviDevice(id),
  setShowModaleConferma: (value) => rootStore.infoStore.setShowModaleConferma(value),
  rimuoviRFID: (externalParams) => rootStore.deviceStore.rimuoviRFID(externalParams),
  getRimuoviRFIDLoading: () => rootStore.deviceStore.getRimuoviRFIDLoading(),
  fetchUserRfid: () => rootStore.userStore.fetchUserRfid(),
  userRfid: rootStore.userStore.userRfid


}))(PuntoControlloComponent)

const PuntiControllo = inject(({ rootStore }) => ({
  devicesLoading: rootStore.deviceStore.devicesLoading,
  devices: rootStore.deviceStore.devices,
  getAll: (hideLoading) => rootStore.deviceStore.getAll(hideLoading),

  openControlPoint: (serialNumber) => rootStore.deviceStore.openControlPoint(serialNumber),
  openControlPointLoading: rootStore.deviceStore.openControlPointLoading,

  setUserToEdit: (value) => rootStore.userStore.setUserToEdit(value),
  setShowModaleConferma: (value) => rootStore.infoStore.setShowModaleConferma(value),
  setSidebarVoice: (val) => rootStore.globalsStore.setSidebarVoice(val)

}))(PuntiControlloComponent)

const NuovoDispositivo = inject(({ rootStore }) => ({
  register: (device) => rootStore.deviceStore.register(device),
  registerLoading: rootStore.deviceStore.registerLoading,
  getAll: () => rootStore.deviceStore.getAll(),
  devices: rootStore.deviceStore.devices,
  setDeviceSetupProcedureStarted: (val) => rootStore.deviceStore.setDeviceSetupProcedureStarted(val),
  deviceSetupProcedureStarted: rootStore.deviceStore.deviceSetupProcedureStarted,
  getAndIncrementPulseErrorCounter: () => rootStore.deviceStore.getAndIncrementPulseErrorCounter(),
  setModalErrorMessage: (val) => rootStore.deviceStore.setModalErrorMessage(val),
  modalErrorMessage: rootStore.deviceStore.modalErrorMessage,
  setCheckingNetworkAuth: (val) => rootStore.deviceStore.setCheckingNetworkAuth(val),
  checkingNetworkAuth: rootStore.deviceStore.checkingNetworkAuth,
  isCheckingNetworkAuth: () => rootStore.deviceStore.isCheckingNetworkAuth(),
  resetPulseErrorCounter: () => rootStore.deviceStore.resetPulseErrorCounter(),
  getAndIncrementOperationErrorWhileCommunicatingCounter: () => rootStore.deviceStore.getAndIncrementOperationErrorWhileCommunicatingCounter(),
  resetOperationErrorWhileCommunicatingCounter: () => rootStore.deviceStore.resetOperationErrorWhileCommunicatingCounter()
}))(NuovoDispositivoComponent)

const NuovoOspite = inject(({ rootStore }) => ({
  register: (device) => rootStore.deviceStore.register(device),
  registerLoading: rootStore.deviceStore.registerLoading,
  getAll: () => rootStore.deviceStore.getAll(),
  devices: rootStore.deviceStore.devices,
  setGuestRegistrationProcedureStarted: (val) => rootStore.userStore.setGuestRegistrationProcedureStarted(val),
  guestRegistrationProcedureStarted: rootStore.userStore.guestRegistrationProcedureStarted,
  getAndIncrementPulseErrorCounter: () => rootStore.deviceStore.getAndIncrementPulseErrorCounter(),
  setModalErrorMessage: (val) => rootStore.deviceStore.setModalErrorMessage(val),
  modalErrorMessage: rootStore.deviceStore.modalErrorMessage,
  setCheckingNetworkAuth: (val) => rootStore.deviceStore.setCheckingNetworkAuth(val),
  checkingNetworkAuth: rootStore.deviceStore.checkingNetworkAuth,
  isCheckingNetworkAuth: () => rootStore.deviceStore.isCheckingNetworkAuth(),
  resetPulseErrorCounter: () => rootStore.deviceStore.resetPulseErrorCounter(),
  getAndIncrementOperationErrorWhileCommunicatingCounter: () => rootStore.deviceStore.getAndIncrementOperationErrorWhileCommunicatingCounter(),
  resetOperationErrorWhileCommunicatingCounter: () => rootStore.deviceStore.resetOperationErrorWhileCommunicatingCounter(),
  generatePin: (pinNumber) => rootStore.deviceStore.generatePin(pinNumber),
  generatedPin1: rootStore.deviceStore.generatedPin1,
  devices: rootStore.deviceStore.devices,
  getAll: () => rootStore.deviceStore.getAll(),
  registerUserLoading: rootStore.userStore.registerUserLoading,
  registerUser: (user) => rootStore.userStore.registerUser(user),
  pairUserToDevice: (info) => rootStore.userStore.pairUserToDevice(info),
  lastUserRegisteredId: rootStore.userStore.lastUserRegisteredId
}))(NuovoOspiteComponent)

const ModificaOspite = inject(({ rootStore }) => ({
  guestToEdit: rootStore.userStore.guestToEdit,
  editGuest: (id) => rootStore.userStore.editGuest(id),
  devices: rootStore.deviceStore.devices,
  pairUserToDevice: (info) => rootStore.userStore.pairUserToDevice(info),
  generatePin: (pinNumber) => rootStore.deviceStore.generatePin(pinNumber),
  generatedPin1: rootStore.deviceStore.generatedPin1,
  setGeneratedPin: (pinNumber, value) => rootStore.deviceStore.setGeneratedPin(pinNumber, value),
  registerUser: (user) => rootStore.userStore.registerUser(user),
  readUsers: (hideLoading) => rootStore.userStore.readUsers(hideLoading),
  getAllDevices: () => rootStore.deviceStore.getAll(),
  sendSMS: (info) => rootStore.userStore.sendSMS(info),
  onDeleteGuest: (id) => rootStore.userStore.onDeleteGuest(id),
  setShowModaleConferma: (value) => rootStore.infoStore.setShowModaleConferma(value),
  getAvailablesRfids: (guestId) => rootStore.rfidStore.getAvailablesRfids(guestId),
  availablesRfids: rootStore.rfidStore.availablesRfids

}))(ModificaOspiteComponent)

export {
  PuntiControllo,
  NuovoDispositivo,
  Guests,
  PuntoControllo,
  NuovoOspite,
  ModificaOspite,
  Tessere
}
 