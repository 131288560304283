// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.device_card {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Old versions of Firefox */
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    padding: .5em;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 1.25em;
}

* {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Old versions of Firefox */
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.device_card>div {
    width: 100%;
    margin: auto;
    text-align: center;
    padding-top: .8em;
    padding-bottom: .8em;
}

.device_card>div:last-child {
    border-right: 0;
}`, "",{"version":3,"sources":["webpack://./css/device_card.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;IACf,yBAAyB;IACzB,WAAW;IAEX,mBAAmB;IAEnB,4BAA4B;IAE5B,2BAA2B;IAC3B,iBAAiB;IACjB;gFAC4E;;IAE5E,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,yBAAyB;IACzB,WAAW;IAEX,mBAAmB;IAEnB,4BAA4B;IAE5B,2BAA2B;IAC3B,iBAAiB;IACjB;gFAC4E;AAChF;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".device_card {\n    -webkit-touch-callout: none;\n    /* iOS Safari */\n    -webkit-user-select: none;\n    /* Safari */\n    -khtml-user-select: none;\n    /* Konqueror HTML */\n    -moz-user-select: none;\n    /* Old versions of Firefox */\n    -ms-user-select: none;\n    /* Internet Explorer/Edge */\n    user-select: none;\n    /* Non-prefixed version, currently\n                                  supported by Chrome, Edge, Opera and Firefox */\n\n    padding: .5em;\n    border-radius: 8px;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n    margin-bottom: 1.25em;\n}\n\n* {\n    -webkit-touch-callout: none;\n    /* iOS Safari */\n    -webkit-user-select: none;\n    /* Safari */\n    -khtml-user-select: none;\n    /* Konqueror HTML */\n    -moz-user-select: none;\n    /* Old versions of Firefox */\n    -ms-user-select: none;\n    /* Internet Explorer/Edge */\n    user-select: none;\n    /* Non-prefixed version, currently\n                                  supported by Chrome, Edge, Opera and Firefox */\n}\n\n.device_card>div {\n    width: 100%;\n    margin: auto;\n    text-align: center;\n    padding-top: .8em;\n    padding-bottom: .8em;\n}\n\n.device_card>div:last-child {\n    border-right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
