import { RouterStore } from 'mobx-react-router';

import AjaxService from 'Services/ajax-service'

import UserStore from './user-store';
import DeviceStore from './device-store';

import InfoStore from './info-store';
import LogStore from './log-store';

import GlobalsStore from './globals-store';

import RfidStore from './rfid-store';

class RootStore {
  constructor(onRequestConfig, onRequestConfigError, onResponse, onResponseError, onNoServerResponse) {
    this.service = new AjaxService(onRequestConfig, onRequestConfigError, onResponse, onResponseError, onNoServerResponse)

    this.userStore = new UserStore(this);
    this.deviceStore = new DeviceStore(this);
    this.infoStore = new InfoStore(this);
    this.logStore = new LogStore(this);
    this.routingStore = new RouterStore();
    this.globalsStore = new GlobalsStore(this);
    this.rfidStore = new RfidStore(this);
  }
}

export default RootStore;