import React from 'react';
import { Button, TextField, Typography, Container } from '@mui/material';


import Logo from 'Img/logo.png'

class PasswordRecovery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onSendRecoveryLink() {
    if (!this.state.email) {
      this.setState({
        emailError: !this.state.email ? true : false
      });
      return;
    }

    let user = {
      email: this.state.email
    }

    this.props.sendRecoveryLink(user);
  }

  render() {
    //Se loggato vai in home
    if (this.props.getUser() && this.state.config) {
      const context = `/${this.state.config.context}`;
      location.href = context + "/dispositivi"
    }
    return (
      <div className="row mt-4">
        <div className="col">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div >
              <div className="row">
                <div className="col text-center">
                  <img src={Logo} width="250px" style={{ marginLeft: "0", width: "250px" }} />
                </div>
              </div>
              <hr />

              <div style={{ textAlign: "center" }}>
                <Avatar style={{ margin: "auto" }}>
                  <PasswordIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Recupero password
                </Typography>
              </div>
              <form noValidate>
                <TextField
                  error={this.state.emailError}
                  variant="outlined"
                  margin="normal"
                  disabled = {this.props.sendRecoveryLinkSuccess}
                  required
                  fullWidth
                  id="email"
                  label="Indirizzo Email"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={(e) => { this.setState({ emailError: false, email: e.currentTarget.value }) }}
                  autoFocus
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.onSendRecoveryLink()
                    }
                  }}
                />
                <Button
                  type="button"
                  onClick={() => this.onSendRecoveryLink()}
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled = {this.props.sendRecoveryLinkLoading || this.props.sendRecoveryLinkSuccess}
                  style={{ marginTop: "1em" }}
                >
                  Invia link recupero
                </Button>
              </form>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default PasswordRecovery
export { PasswordRecovery }