// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkReportRow{
    border:0;
    background:rgba(0,0,0,0);
    outline: none;
    padding:0;
    margin:0;
    font-size: 180%;
    color: rgb(0,0,0);
}
.checkReportRow:focus{
    outline:none;
    color: rgb(0,0,0);
}`, "",{"version":3,"sources":["webpack://./css/report.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,wBAAwB;IACxB,aAAa;IACb,SAAS;IACT,QAAQ;IACR,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".checkReportRow{\n    border:0;\n    background:rgba(0,0,0,0);\n    outline: none;\n    padding:0;\n    margin:0;\n    font-size: 180%;\n    color: rgb(0,0,0);\n}\n.checkReportRow:focus{\n    outline:none;\n    color: rgb(0,0,0);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
