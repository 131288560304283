import React from 'react';

import 'Css/sidebar.css';
import LogoSolo from 'Img/logo-solo.png';
import { LuLayoutPanelLeft } from "react-icons/lu";
import { FaUserCircle } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { Link } from "react-router-dom";

const withContextPath = (path) => `${process.env.CONTEXT_PATH}/${path}`;

class SideBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      delayItemsMenu: 300,
      timbraPressed: false
    };
  }

  onToggleSidebar() {
    this.props.setSideBarVisibility(!this.props.showSideBar);
  }


  render() {
    
    if (!this.state.voiceMenuSelected) {
      let flagVoiceMenuSelected = false;

      if (flagVoiceMenuSelected) {
        this.setState({ voiceMenuSelected: true });
      }
    }

    return (
      <>
        <div id={"dmd-sidebar"} className={this.props.sidebarVoice}>
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "dispositivi" ? " selected" : "")} to={withContextPath("dispositivi")}>
            <img src={LogoSolo} width="32" height="32" />
            <label>DISPOSITIVI</label>
          </Link>
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "ospiti" ? " selected" : "")} to={withContextPath("guests")}>
            <FaUserCircle />
            <label>OSPITI</label>
          </Link>
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "report" ? " selected" : "")} to={withContextPath("report")}>
            <TbReportAnalytics />
            <label>REPORT</label>
          </Link>
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "pannello" ? " selected" : "")} to={withContextPath("control_panel")}>
            <LuLayoutPanelLeft />
            <label>PANNELLO</label>
          </Link>
        </div>
      </>
    )
  }
}

export default SideBar
export { SideBar }