import axios from 'axios';

class AjaxService {
  constructor(onRequestConfig, onRequestConfigError, onResponse, onResponseError, onNoServerResponse) {
    this.onRequestConfig = onRequestConfig && (typeof onRequestConfig === 'function') ? onRequestConfig : () => { }
    this.onRequestConfigError = onRequestConfigError && (typeof onRequestConfigError === 'function') ? onRequestConfigError : () => { }
    this.onResponse = onRequestConfig && (typeof onResponse === 'function') ? onResponse : () => { }
    this.onResponseError = onResponseError && (typeof onResponseError === 'function') ? onResponseError : () => { }
    this.onNoServerResponse = onNoServerResponse && (typeof onNoServerResponse === 'function') ? onNoServerResponse : () => { }
    this.source = axios.CancelToken.source();
  }

  /**
   * Cancella l'ultima request (se si trova in pending) effettuata
   */
  cancelRequest(){
    this.source.cancel();
    this.source = axios.CancelToken.source();
  }

  doAjax(method, path, data, authorization) {

    const uri = `${path}`

    const config =  {
      method: method,
      url: uri,
      data: data ? JSON.stringify(data) : null,
      headers: {
        "Content-Type": "application/json",
        "Authorization": authorization
      }
    }

    this.onRequestConfig.call(this, config)
    config.cancelToken= this.source.token;

    const request = axios(config);

    return request.then( (response) => {
      console.debug(`request ${uri} - config ${JSON.stringify(response)}`)
      this.onResponse.call(this, response)
      return { success: true, result: response.data, authorization: response.headers.authorization }
    })
      .catch(error => {
        if (error.response) {
          this.onResponseError.call(this, error)
          return { success: false, result: error.response.data }
        } else if (error.request) {
          this.onNoServerResponse.call(this, error)
          console.debug(`no response received ${uri} - error ${JSON.stringify(error.request)}`)
        } else {
          this.onRequestConfigError.call(this, error)
          console.debug(`request config error ${uri} - error ${JSON.stringify(error.message)}`)
        }
        console.debug(`request ${uri} - config ${JSON.stringify(error.config)}`)
      });
  }

}

export default AjaxService;
