import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModaleSMS extends React.Component {

  constructor(props) {
    super(props);
  }

  onConferma() {
    let config = require('../../../../config/config.json');
    const context = `/${config.context}`;
    location.href = context + "/guests";
  }

  render() {
    return this.props.showModaleSMS ? (
      <Modal backdrop="static" show={this.props.showModaleSMS ? true : false} onHide={() => this.props.setShowModaleSMS(null)}>
        <Modal.Header>
          <Modal.Title>{this.props.showModaleSMS.head}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.showModaleSMS.body}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" disabled={!this.props.showModaleSMS.confirmed} onClick={() => this.onConferma()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    ) : ""
  }
}

export default ModaleSMS
export { ModaleSMS }