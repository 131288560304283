import React from 'react';
import Modal from 'react-bootstrap/Modal';
import 'Css/modifica_ospite.css';

class ModificaOspite extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      section: "ospite",
      devices: {},
      checkDevices: false,
      guest: {}
    }
  }

  onConferma(save) {
    if (save) {
      let guest = this.state.guest;
      guest.devices = [];
      if (this.state.devices) {
        for (const [key, device] of Object.entries(this.state.devices)) {
          let dev = {};
          dev["deviceId"] = device.id;
          dev["deviceName"] = device.name;
          dev["untilDate"] = device.limitedDate ? device.limitedDate : "unlimited";
          guest.devices.push(dev);
          // this.props.pairUserToDevice({
          //   userId: this.props.guestToEdit.id,
          //   deviceId: device.id,
          //   deviceName: device.name,
          //   untilDate: device.limitedDate ? device.limitedDate : "unlimited",
          //   sendSMS: device.sendSMS ? true : false
          // });
        }
      }

      this.props.registerUser(guest);
    }

    this.props.setGeneratedPin(1, null);
    this.props.editGuest(null);
    this.setState({
      devices: {},
      section: "ospite",
      checkDevices: false,
      selectedDevice: null,
      guest: {}
    });

    setTimeout(() => {
      this.props.readUsers(true);
      this.props.getAllDevices();
    }, 500);
  }

  onAbilita(device, enable) {
    let devices = this.state.devices;
    if (enable) {
      device.limited = null;
      devices[device.id] = device;
    } else {
      this.setLimitedOnDevice(device, false); //Per azzerare valore in input date
      delete devices[device.id];
    }
    this.setState({ devices: devices });
  }

  setLimitedOnDevice(device, limited) {
    let devices = this.state.devices;
    devices[device.id].limited = limited ? limited : null;
    if (!limited) {
      delete devices[device.id].limitedDate;
      $("#input-date").val("");
    } else {
      devices[device.id].limitedDate = new Date().toISOString().split('T')[0];
    }
    this.setState({ devices: devices });
  }

  changeDeviceLimitedDate(deviceId, e) {
    let value = e.currentTarget.value;
    let devices = this.state.devices;
    devices[deviceId].limitedDate = value;
    this.setState({ devices: devices });
  }

  onChangeGuestInfo(infoKey, value) {
    let guest = this.state.guest;
    guest[infoKey] = value;
    this.setState({
      guest: guest
    });
  }

  onSendSMS(device) {
    let info = {
      user: this.state.guest.name + " " + this.state.guest.lastName,
      pins: this.state.guest.pin[0] + "",
      expirationDates: device.limitedDate ? device.limitedDate : "illimitato",
      accessPoint: device.name,
      number: this.state.guest.cellNumber
    }

    this.props.sendSMS(info);
  }

  onDelete() {
    this.props.setShowModaleConferma({
      header: "Elimina ospite",
      body: "Procedere con l'eliminazione?",
      esegui: () => {
        this.props.onDeleteGuest(this.state.guest.id);
        this.props.setShowModaleConferma(null)
      }
    });

  }

  render() {
    if (this.props.guestToEdit && jQuery.isEmptyObject(this.state.guest)) {
      let guest = JSON.parse(JSON.stringify(this.props.guestToEdit)); //Deep clone
      this.props.getAvailablesRfids(guest.id);
      let pin = guest.pin.filter(p => p.value != null)[0].value;
      guest.pin = [];
      guest.pin[0] = pin;
      guest.isUpdate = "isUpdate"
      this.setState({
        guest: guest
      });
    }


    let rfidsHtml = this.props.availablesRfids && this.props.availablesRfids.length > 0 ? this.props.availablesRfids.map(rfid => {
      return rfid.id == this.state.guest.rfid ? (
        <option value={rfid.id} selected="selected">{rfid.name}</option>
      ) : (
        <option value={rfid.id}>{rfid.name}</option>
      )
    }) : "";

    if (!jQuery.isEmptyObject(this.state.guest) && this.props.generatedPin1 && this.state.guest.pin[0] != this.props.generatedPin1) {
      let guest = this.state.guest;
      guest.pin[0] = this.props.generatedPin1 + "";
      this.setState({
        guest: guest
      });
    }

    if (!this.state.checkDevices && this.props.devices && !jQuery.isEmptyObject(this.state.guest)) {
      this.setState({
        checkDevices: true
      });

      this.props.devices.map(device => { //Abilitazione device ospite se ha i permessi con relative date di scadenza
        if (device.guests) {
          device.guests.map(guest => {
            if (this.state.guest.id == guest.id) {
              let devices = this.state.devices;
              devices[device.id] = device;

              if (guest.expirationDate) {
                devices[device.id].limited = true;
                devices[device.id].limitedDate = guest.expirationDate.split("T")[0];
              }

              this.setState({
                devices: devices
              });
            }
          });
        }
      });
    }

    let devicesHtml = this.props.devices ? this.props.devices.map(device => {
      return (
        <>
          <div className="device" onClick={() => this.setState({ selectedDevice: this.state.selectedDevice != device.id ? device.id : null })}>
            {this.state.selectedDevice == device.id ?
              <><i class="fa-solid fa-angle-down"></i> <strong>{device.name}</strong></>
              :
              <><i class="fa-solid fa-chevron-right"></i> {device.name}</>}
            {device.id in this.state.devices ?
              <i class="fa-solid fa-circle-check ml-1 text-success"></i>
              :
              <i class="fa-solid fa-ban ml-1 text-danger"></i>
            }
          </div>
          {this.state.selectedDevice == device.id ?
            <div className="device-details">
              {device.id in this.state.devices ?
                <>
                  <button id="remove-permission" onClick={() => this.onAbilita(device, false)}><i class="fa-solid fa-ban"></i> DISABILITA</button>
                  <div id="lim-unlim">
                    <div onClick={() => this.setLimitedOnDevice(device, false)} className={this.state.devices[device.id].limited == null ? "selected" : ""}>
                      <label id="device-details-unlimited-btn">ILLIMITATO</label>
                    </div>
                    <div onClick={() => { $("#input-date").focus(); this.setLimitedOnDevice(device, true); }} className={this.state.devices[device.id].limited != null ? "selected" : ""}>
                      <label id="device-details-until-btn">FINO AL...</label>
                      <input type="date" value={this.state.devices[device.id].limitedDate} id="input-date" min={new Date().toISOString().split('T')[0]} onChange={(e) => this.changeDeviceLimitedDate(device.id, e)} />
                    </div>
                  </div>
                  <div id="sms">
                    <button id="sms-btn" disabled={!this.state.guest.cellNumber} onClick={() => this.onSendSMS(this.state.devices[device.id])}><i class="fa-solid fa-comment-sms"></i> Invia SMS</button>
                  </div>
                </>
                :
                <button id="add-permission" onClick={() => this.onAbilita(device, true)}><i class="fa-solid fa-circle-check"></i> ABILITA</button>
              }
            </div> : ""}
        </>)
    }) : "";

    return this.props.guestToEdit ? (
      <Modal id="edit-guest" backdrop="static" show={this.props.guestToEdit ? true : false} onHide={() => this.onConferma(false)}>
        <Modal.Header>
          <Modal.Title id="title"><i className="fa-solid fa-address-card"></i> Modifica Ospite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="sections">
            <button id="ospite-btn" className={this.state.section == "ospite" ? "section-selected" : ""} onClick={() => this.setState({ section: "ospite" })}>OSPITE</button>
            <button id="permessi-btn" className={this.state.section == "permessi" ? "section-selected" : ""} onClick={() => this.setState({ section: "permessi" })}>PERMESSI</button>
            <button id="elimina-btn" onClick={() => this.onDelete()}><i class="fa-solid fa-trash"></i> ELIMINA</button>
          </div>
          {this.state.section == "ospite" ?
            <div id="guest-details">
              <label>NOME</label> <input type="text" value={this.state.guest.name} onChange={(e) => this.onChangeGuestInfo("name", e.currentTarget.value)} />
              <label>COGNOME</label> <input type="text" value={this.state.guest.lastName} onChange={(e) => this.onChangeGuestInfo("lastName", e.currentTarget.value)} />
              <label>EMAIL</label> <input type="text" value={this.state.guest.email} onChange={(e) => this.onChangeGuestInfo("email", e.currentTarget.value)} />
              <label>CELLULARE</label> <input type="text" value={this.state.guest.cellNumber} onChange={(e) => this.onChangeGuestInfo("cellNumber", e.currentTarget.value)} />
              <div id="pin">
                <label>PIN</label>
                <input type="text" id="pin-input" placeholder="PIN" disabled={true} value={!jQuery.isEmptyObject(this.state.guest) ? this.state.guest.pin[0] : ""} />
                <button id="regenerate-btn" onClick={() => this.props.generatePin(1)}><i className="fa-solid fa-arrows-rotate"></i> Rigenera</button>
              </div>
              <div id="rfid">
                <label>TESSERA</label>
                <select onChange={(e) => this.onChangeGuestInfo("rfid", e.currentTarget.value == "null" ? null : e.currentTarget.value)}>
                  <option value={"null"}>Nessuna</option>
                  {rfidsHtml}
                </select>
              </div>
            </div>
            :
            <div>
              {devicesHtml}
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div id="btns">
            <button id="cancel-btn" onClick={() => this.onConferma(false)}>Annulla</button>
            <button id="save-btn" onClick={() => this.onConferma(true)}>Salva <i class="fa-solid fa-floppy-disk"></i></button>
          </div>
        </Modal.Footer>
      </Modal>
    ) : ""
  }
}

export default ModificaOspite
export { ModificaOspite }