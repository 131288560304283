import React from 'react';

import { Router, Route, Switch, Redirect, Link } from 'react-router-dom';

class Footer extends React.Component {

  constructor(props) {
    super(props);

  }
 
  componentDidMount() {
  }

  render() {

    const { match } = this.props;

    return (
      <footer id="sticky-footer" className="py-4 bg-dark text-white-50">
        <div className="container text-center">
          <small>Copyright &copy; Majorbit</small>
        </div>
      </footer>

    )
  }
}

export default Footer
export {Footer}