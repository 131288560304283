// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#rfid-cards{
    border: 3px solid rgba(10,10,10,.5);
    box-shadow: 0 0 15px 2px rgba(10,10,10,.5);
    text-align: left;
    padding: 1em;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

#rfid-cards-head-title{
    font-weight: bold;
    font-size: 130%;
    color: rgb(100,100,100);
    margin-bottom: 1em!important;
}
#rfid-cards div {
    border: 0;
    font-size: 120%;
}

#rfid-cards div label{
    margin-left: .5em;
}

/* Stili solo per dispositivi con larghezza massima di 600 pixel (tipicamente dispositivi mobili) */
@media only screen and (max-width: 600px) {
    #rfid-cards div {
        font-size: 90%!important;
    }
    #rfid-cards {
        padding: .5em!important;
        margin: 0.5em;
    }
    #rfid-cards div i {
        font-size: 140%;
    }
    #rfid-cards div label {
        font-size: 140%!important;
    }
}`, "",{"version":3,"sources":["webpack://./css/rfid.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,0CAA0C;IAC1C,gBAAgB;IAChB,YAAY;IACZ,kCAA0B;YAA1B,0BAA0B;IAC1B,6BAA6B;IAC7B,+BAA+B;AACnC;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,uBAAuB;IACvB,4BAA4B;AAChC;AACA;IACI,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA,mGAAmG;AACnG;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,uBAAuB;QACvB,aAAa;IACjB;IACA;QACI,eAAe;IACnB;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["#rfid-cards{\n    border: 3px solid rgba(10,10,10,.5);\n    box-shadow: 0 0 15px 2px rgba(10,10,10,.5);\n    text-align: left;\n    padding: 1em;\n    backdrop-filter: blur(4px);\n    border-top-right-radius: 10px;\n    border-bottom-left-radius: 10px;\n}\n\n#rfid-cards-head-title{\n    font-weight: bold;\n    font-size: 130%;\n    color: rgb(100,100,100);\n    margin-bottom: 1em!important;\n}\n#rfid-cards div {\n    border: 0;\n    font-size: 120%;\n}\n\n#rfid-cards div label{\n    margin-left: .5em;\n}\n\n/* Stili solo per dispositivi con larghezza massima di 600 pixel (tipicamente dispositivi mobili) */\n@media only screen and (max-width: 600px) {\n    #rfid-cards div {\n        font-size: 90%!important;\n    }\n    #rfid-cards {\n        padding: .5em!important;\n        margin: 0.5em;\n    }\n    #rfid-cards div i {\n        font-size: 140%;\n    }\n    #rfid-cards div label {\n        font-size: 140%!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
