import React from 'react';

import { Router, Route, Switch, Redirect, Link } from 'react-router-dom';

import {SideBar} from 'Containers/main_layout'

import Logo from 'Img/logo.png'
import LogoSolo from 'Img/logo-solo.png';
import LogoNoLogo from 'Img/logo-no-logo.png';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  
  render() {    
    let navStyle = {
      borderBottom: "2px rgba(0,0,0,.2) solid"
    };

    return (
      <>
        {/* <nav id="nav" className="navbar navbar-dark bg-light navbar-header mb-3" style={navStyle}>
          <div className="row header-main-row">
            <div className={"col align-self-center" + (this.state.mobileVer? " header-part1-col-mobile" : "") } style={{zIndex:"99"}}>
            </div>
          </div>
        </nav> */}

      </>
    )
  }
}

export default Header
export {Header}